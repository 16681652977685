import gql from "graphql-tag";

const GET_SALES = gql`
    query getSales($orderId: [ID!]!) {
        getSalesList(orderIDs: $orderId) {
            data
            File {
                filename
                mimeType
                encoding
            }
        }
    }
`;

export default GET_SALES;

