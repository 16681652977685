import React, {Component} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ContentContainer from "../container/ContentContainer";
import DashboardContainer from "../container/DashboardContainer";
import ProductList from "./Products/ProductList";
import '../styles/App.css';
import { ApolloProvider } from '@apollo/react-hooks';
import { PrivateRoute } from "./Login/Login";
import  LoginComponent  from "./Login/LoginComponent";
import { AppHeader } from "./AppHeader";
import OrderList from "./Order/OrderList";
import UsersList from "./Users/UsersList";
import RecipesList from "./Recipes/RecipesList";
import { HttpLink } from 'apollo-link-http';
import {ApolloLink, FetchResult, NextLink, Operation} from 'apollo-link';
import {Observable} from "apollo-client/util/Observable";
import {InMemoryCache, NormalizedCacheObject} from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import AddRecipe from "./Recipes/AddRecipe";
import { createUploadLink } from 'apollo-upload-client';
import Manage from "./Manage/Manage";
import Stocks from "./Stocks/Stocks";
import ArchivedOrderList from "./ArchivedOrder/ArchivedOrderList";
import Sales from "./Sales/Sales";

const InitialState = {
    token: window.localStorage.getItem("token"),
    search: "Pomme",
    component: LoginComponent,
};

type AuthState = Readonly<typeof InitialState>

export const PAGE_SIZE = 20;

export const AuthContext = React.createContext(InitialState);

const request = (operation:Operation) => {
    let token = localStorage.getItem('token');
    operation.setContext({
    headers: {
        Authorization: "Bearer " + token
    }
  });
};

const requestLink = new ApolloLink((operation:Operation, forward?:NextLink): Observable<FetchResult> | null =>
  new Observable(observer => {
    let handle :ZenObservable.Subscription | undefined;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward && forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
        requestLink,
        ApolloLink.split(
            op => op.getContext().hasUpload,
            createUploadLink({uri: process.env.REACT_APP_SERVER_URI}),
            new HttpLink({uri: process.env.REACT_APP_SERVER_URI}),
        ),
    ])
});

class App extends Component<object, AuthState> {

    constructor(props:any) {
        super(props);
        this.state = {
            token: window.localStorage.getItem("token"),
            search: "",
            component: LoginComponent,
        };
    }

    render() {
        let tmp = this.state;
        return(
            <ApolloProvider client={client}>
                <AuthContext.Provider value={tmp}>
                <Router>
                    <div className="App">
                        {AppHeader()}
                        <ContentContainer>
                            <PrivateRoute path={"/"} exact component={DashboardContainer}/>
                            <PrivateRoute path={"/commandes/:skip/:first"} exact component={OrderList}/>
                            <PrivateRoute path={"/archives/:sort(type_ASC|type_DESC|variety_DESC|variety_ASC)/:skip/:first"} exact component={ArchivedOrderList}/>
                            <PrivateRoute path={"/produits/:sort(type_ASC|type_DESC|variety_DESC|variety_ASC)/:skip/:first"} exact component={ProductList}/>
                            <PrivateRoute path={"/utilisateurs/:skip/:first"} exact component={UsersList}/>
                            <PrivateRoute path={"/recettes/:skip/:first"} exact component={RecipesList}/>
                            <PrivateRoute path={"/recettes/nouvelle"} exact component={AddRecipe}/>
                            <PrivateRoute path={"/gestion"} exact component={Manage}/>
                            <PrivateRoute path={"/stocks/:skip/:first"} exact component={Stocks}/>
                            <PrivateRoute path={"/ventes"} exact component={Sales}/>
                        </ContentContainer>
                    </div>
                </Router>
                </AuthContext.Provider>
            </ApolloProvider>
        )
    }
}

export default App;
