import React from 'react'
import TableContainer from "../../container/TableContainer";
import AddProductComponent from "./AddProductComponent";
import GET_PRODUCTS from "../../graphql/queries/getAllProducts";
import {Product} from "../../types/Products";
import ProductCell from "./ProductCell";
import {Loading} from "../Misc/Loading";
import Pagination from '../../types/Pagination';
import {client, PAGE_SIZE} from '../App';
import ProductSelect from "../Misc/ProductSelect";
import {UPDATE_PRODUCT_STOCK} from "../../graphql/mutations/UpdateProduct";

const cmpASC = (a:any, b:any) => {if (a.variety.name < b.variety.name)return -1;if (a.variety.name < b.variety.name)return 1;return 0;};
const cmpDESC = (a:any, b:any) => {if (a.variety.name > b.variety.name)return -1;if (a.variety.name > b.variety.name)return 1;return 0;};

interface ProductListState {
    loading: boolean
    query: string
    page: Pagination
    sortBy: string
    data: any
    type: string
    variety: string
}

class ProductList extends React.Component<any, ProductListState> {
    constructor(props: any) {
        super(props);
        let first = 2;
        let skip = 0;
        let next = "";
        let prev = "";
        if (Number(this.props.match.params.skip))
            skip = Number(this.props.match.params.skip);
        if (Number(this.props.match.params.first)) {
            first = Number(this.props.match.params.first);
            next = (skip + first).toString()
        }
        this.state = {
            query: "",
            loading: true,
            page: {totalCount: -1, next:"", prev:"", location:"produits", first:first, skip:skip},
            sortBy: "type_ASC",
            data: null,
            type: "",
            variety: ""
        };
        this.setQuery = this.setQuery.bind(this);
        this.updateMe = this.updateMe.bind(this);
    }

    updateMe(): void {
        let sortDirection = this.props.match.params.sort.substring(0, 5) === "type_" ? this.props.match.params.sort : "type_ASC";
        client.query({query: GET_PRODUCTS, variables: {query:this.state.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()}}).then((res) => {
            //console.log(res.data)
            /*res.data.products.forEach((prod: any) => {
                console.log(prod.id)
                if (prod.stock !== 0)
                    client.mutate({mutation: UPDATE_PRODUCT_STOCK, variables: {id: prod.id, stock: 0}}).then((ra) => console.log(ra))
            })*/
            let tmp = this.state.page;
            tmp.totalCount = res.data.products.length;
            this.setState({page: tmp});
            client.query({query: GET_PRODUCTS, variables: {
                query:this.state.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(), sort:sortDirection,
                    skip: this.state.page.skip, first: this.state.page.first
            }}).then((res) => {
                if (this.props.match.params.sort.substring(0, 8) === "variety_") {
                    if (this.props.match.params.sort === "variety_ASC")
                        res.data.products.sort(cmpASC);
                    else if (this.props.match.params.sort === "variety_DESC")
                        res.data.products.sort(cmpDESC);
                }
                this.setState({data: res.data, loading: false});
            })
        });
    }

    componentDidMount(): void {
        this.updateMe();
    }

    setQuery(query:string) {
        let tmp = this.state.page;
        tmp.skip = 0;
        this.setState({query, page:tmp});
        this.updateMe();
    }

    setType(type:string) {
        if (type.length === 0) {
            this.setState({type:type, variety:""});
            return this.updateMe();
        }
        client.query({query: GET_PRODUCTS, variables: {query:this.state.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(), type:type}}).then((res) => {
            let tmp = this.state.page;
            tmp.totalCount = res.data.products.length;
            client.query({query: GET_PRODUCTS, variables: {query:this.state.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(),
            skip: this.state.page.skip, first: this.state.page.first, type:type}}).then((res) => {
                this.setState({data: res.data, page: tmp, type: type});
            })
        });
    }

    setVariety(variety:string) {
        client.query({query: GET_PRODUCTS, variables: {query:this.state.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(), type:this.state.type, varietyName:variety}}).then((res) => {
            let tmp = this.state.page;
            tmp.totalCount = res.data.products.length;
            client.query({query: GET_PRODUCTS, variables: {query:this.state.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(),
            skip: this.state.page.skip, first: this.state.page.first, type:this.state.type, varietyName:variety}}).then((res) => {
                this.setState({data: res.data, page: tmp, variety: variety});
            })
        });
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let tSort = "▴";
        let vSort = "";
        let SORT_BY = "type_ASC";
        let cmp;
        const typeSortASC = "/produits/type_ASC/0/" + PAGE_SIZE;
        const typeSortDESC = "/produits/type_DESC/0/" + PAGE_SIZE;
        const varietySortASC = "/produits/variety_ASC/0/" + PAGE_SIZE;
        const varietySortDESC = "/produits/variety_DESC/0/" + PAGE_SIZE;
        let tSortFn = typeSortDESC;
        let vSortFn = varietySortASC;

        if (this.props.match.params.sort) {
            switch (this.props.match.params.sort) {
                case "type_ASC":
                    tSort = "▴";
                    vSort = "—";
                    SORT_BY = "type_ASC";
                    tSortFn = typeSortDESC;
                    vSortFn = varietySortASC;
                    break;
                case "type_DESC":
                    tSort = "▾";
                    vSort = "—";
                    SORT_BY = "type_DESC";
                    tSortFn = typeSortASC;
                    vSortFn = varietySortASC;
                    break;
                case "variety_ASC":
                    vSort = "▴";
                    tSort = "—";
                    SORT_BY = "visible_ASC";
                    cmp = cmpASC;
                    vSortFn = varietySortDESC;
                    tSortFn = typeSortASC;
                    break;
                case "variety_DESC":
                    vSort = "▾";
                    tSort = "—";
                    SORT_BY = "visible_ASC";
                    cmp = cmpDESC;
                    vSortFn = varietySortASC;
                    tSortFn = typeSortASC;
                    break;
            }
        }
        if (this.state.loading)
            return (Loading);
        if (cmp)
            this.state.data.products.sort(cmp);
        let productsCell = this.state.data.products.map((product:Product, key:number) =>
            React.createElement(ProductCell, {product, key})
        );
        let typeSelector = React.createElement(ProductSelect, {getVarieties:false, setter:this.setType.bind(this), type:this.state.type});
        return (TableContainer(
            "Liste des produits:",
            "+ Ajouter un produit",
            [
                "",
                typeSelector,
                this.state.type.length ?
                    this.state.type === "FRUIT" ?
                        <ProductSelect getVarieties={true} setter={this.setVariety.bind(this)} type={"FRUIT"} />
                        : React.createElement(ProductSelect, {getVarieties:true, setter:this.setVariety.bind(this), type:"VEGETABLE"})
                    : "Sélectionner un type",
                "Nom du produit",
                "Photo du produit",
                "Prix de vente",
                "Unité",
                "Provenance",
                "Description",
                "Idées recettes associées",
                "Visible"
            ],
            productsCell,
            AddProductComponent(),
            "Nouveau produit:",
            undefined,
            this.setQuery,
            this.state.query,
            [""],
            undefined,
            this.state.page
        ));
    }
}

export default ProductList;
