import React, {Component} from "react";
import "../../styles/DashboardTable.css"
import {Variety} from "../../types/Variety";
import {client} from "../App";
import {GET_FILE} from "../../graphql/queries/getFile";
import manageError from "../Misc/Error";
import LoadingCell from "../Misc/LoadingCell";
import REM_VARIETY from '../../graphql/mutations/RemoveVariety';

interface varietyProps {
    variety: Variety
}

interface State {
    loading: boolean
    photo: any
    errorPop: boolean
}

const ConfirmPopup = (setPopup:any, popup:boolean) : React.ReactElement => {
    return(
        <div className="confirm-container">
            <div className="confirm-content">
                <div className="confirm-box">
                    <div onClick={() => setPopup(!popup)} className="confirm-close">✕</div>
                    <div style={{margin: "30px"}}>
                        <h3>Impossible de supprimer la variété !</h3>
                        <h4>Il existe des produits dans cette variété.<br/>Vous devez d'abord les supprimés.</h4>
                    </div>
                    <div className="confirm-button-container">
                        <div onClick={() => setPopup(!popup)} className="confirm-button-yes">Ok</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

class VarietyListCell extends Component<varietyProps, State> {
    constructor(props: varietyProps) {
        super(props);
        this.state = {
            loading: true,
            photo: null,
            errorPop: false
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.setErrPop = this.setErrPop.bind(this);
    }

    componentDidMount(): void {
        client.query({query: GET_FILE, variables:{id: this.props.variety.photo.id}}).then((data:any) => {
            this.setState({photo: data.data.getFile, loading: false})
        }).catch((error) => {
            manageError(error);
        })
    }

    handleRemove() {
        client.mutate({
            mutation: REM_VARIETY,
            variables: {id: this.props.variety.id}
        }).then(() => {window.location.reload()}).catch((err) => {
            this.setState({errorPop: true});
        })
    }

    setErrPop(val:boolean) {
        this.setState({errorPop:val});
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (
                LoadingCell(["", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-test"])
            );
        if (this.state.errorPop)
            return (ConfirmPopup(this.setErrPop, this.state.errorPop));
        return (
            <div className="dash-table-row">
                <div className="dash-table-empty"><button onClick={() => {this.handleRemove()}} className="dash-table-button">X</button></div>
                <div className="dash-table-cell">{this.props.variety.type === "FRUIT" ? "Fruit" : "Légume"}</div>
                <div className="dash-table-cell">{this.props.variety.name}</div>
                <div className="dash-table-cell table-cell-img">
                    <img alt={this.state.photo.File.filename} src={"data:" + this.state.photo.File.mimeType + ";base64," + this.state.photo.data} />
                </div>
                <div className="dash-table-cell">
                    <input value={this.props.variety.main_color} type="color" disabled/>
                </div>
            </div>
        )
    }
}

export default VarietyListCell;
