import gql from "graphql-tag";

const DASHBOARD = gql`
    query {
        orders {id, price}
        recipes {id}
        users(where: {type: USER}) {id}
        products{id}
    }
`;

export const DASHBOARD_NEW = gql`
    query dashboard_new($date: DateTime!) {
        orders(where: {created_at_gt: $date}) {
            id
            price
        }
    }
`;

export default DASHBOARD;
