import React, { Component } from 'react'
import logo from '../../img/logo.png'
import '../../styles/navbar.css'
import NavbarLink from "./NavbarLink";
import {Link} from "react-router-dom";
import {client, PAGE_SIZE} from '../App';
import {DASHBOARD_NEW} from '../../graphql/queries/getDashboard';
import moment from "moment";

interface State {
    loading: boolean,
    newOrder: boolean
}

export class Navbar extends Component<object, State> {
    constructor(props:object) {
        super(props);
        this.state = {
            loading: true,
            newOrder: false,
        }
    }

    componentDidMount(): void {
        client.query({query: DASHBOARD_NEW, variables: {date: moment().startOf("day").format()}}).then((res) => {
            this.setState({loading: false, newOrder: !!res.data.orders.length});
        })
    }

    render() {
        return (
            <nav>
                <div className="navbar-content">
                    <div className="navbar-logo">
                        <Link to={"/"}><img className="nav-logo" src={logo} alt="logo" /></Link>
                    </div>
                    <div className="navbar-link">
                        <ul className="nav">
                            <NavbarLink label="Dashboard" activeOnlyWhenExact={true} notify={false} to={"/"} />
                            <NavbarLink label="Commandes" activeOnlyWhenExact={false} notify={this.state.newOrder} to={"/commandes/0/" + PAGE_SIZE}/>
                            <NavbarLink label="Produits" activeOnlyWhenExact={false} notify={false} to={"/produits/type_ASC/0/" + PAGE_SIZE} />
                            <NavbarLink label="Comptes utilisateurs" activeOnlyWhenExact={false} notify={false} to={"/utilisateurs/0/" + PAGE_SIZE} />
                            <NavbarLink label="Recettes" activeOnlyWhenExact={false} notify={false} to={"/recettes/0/" + PAGE_SIZE} />
                            <NavbarLink label="Stocks" activeOnlyWhenExact={false} notify={false} to={"/stocks/0/" + PAGE_SIZE} />
                            <NavbarLink label="Ventes" activeOnlyWhenExact={false} notify={false} to={"/ventes"} />
                            <NavbarLink label="Gestion" activeOnlyWhenExact={false} notify={false} to={"/gestion"} />
                            <NavbarLink label="Archives" activeOnlyWhenExact={false} notify={false} to={"/archives/type_ASC/0/" + PAGE_SIZE} />
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar;
