import React from 'react'
import "../../styles/DashboardTable.css"

type DashboardTableProps = {
    header: Array<string|React.ReactNode>
    data: any
    popup?: boolean
    headerLink?: Array<string>
}

export function DashboardTable(props:DashboardTableProps) {
        let TableHeader = props.header.map((label, key) =>
            <div
                key={key}
                className={(typeof label === "string" && label.length === 0) ? ("dash-table-empty") : (label === "Nombre de commandes passées" || label === "Téléphone" || label === "Prix" || label === "Status" || label === "Date de livraison" || label === "Facture") ? "dash-table-reduce" :
                    label === "Quantité" ? "dash-table-quantity" : label === "Adresse de livraison" ? "dash-table-expand" : "dash-table-label"}>
                {(props.headerLink) ?
                    ((key < props.headerLink.length) ?
                        ((props.headerLink[key].length) ?
                            <a className="no-link" href={props.headerLink[key]}>{label}</a>
                            : label)
                        : label)
                : label}
            </div>
        );

        return (
            <div className="table">
                <div className="dash-table-header">
                    {TableHeader}
                </div>
                <div className={props.popup ? "dash-table-data" : ""}>
                    {props.data}
                </div>
            </div>
        )
}

export default DashboardTable;
