import React, {Component} from 'react';
import {DeliveryLocation} from '../../types/DeliveryLocation';
import {client} from '../App';
import ALL_DELIVERY_LOCATIONS from '../../graphql/queries/getAllDeliveryLocation';
import {Loading} from '../Misc/Loading';
import DeliveryCodeListCell from './DeliveryCodeListCell';
import TableContainer from '../../container/TableContainer';

interface State {
    loading: boolean
    deliveries: Array<DeliveryLocation>
}

class DeliveryCodeList extends Component<object, State> {
    constructor(props:object) {
        super(props);
        this.state = {
            loading: true,
            deliveries: []
        }
    }

    componentDidMount(): void {
        client.query({query:ALL_DELIVERY_LOCATIONS}).then((res) => {
            this.setState({deliveries: res.data.deliveryLocations, loading: false})
        }).catch((err) => {
            //console.log(err);
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (Loading);
        let delLocCell = this.state.deliveries.map((delLoc:DeliveryLocation) =>
            <DeliveryCodeListCell delLoc={delLoc} key={delLoc.id}/>
        );
        return (
            TableContainer(
                "Liste des points de livraison",
                "",
                [
                    "Jour",
                    "Crénau",
                    "Ville",
                    "Actif"
                ],
                delLocCell,
                null,
                "",
                "",
                "",
                "",
                [],
                true
            )
        )
    }

}

export default DeliveryCodeList;
