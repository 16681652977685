import TableContainer from "../../container/TableContainer";
import GET_ORDERS, {GET_ARCHIVED_ORDERS} from "../../graphql/queries/getAllOrders";
import {Loading} from "../Misc/Loading";
import OrderListCell from "./ArchivedOrderListCell";
import * as React from "react";
import {Order} from "../../types/Orders";
import Pagination from '../../types/Pagination';
import { client } from "../App";
import {GET_USERS_BY_ID} from "../../graphql/queries/getAllUsers";
import {User} from "../../types/Users";

interface State {
    loading: boolean
    query: string
    page: Pagination
    data: any
    users: Array<User>
}

class ArchivedOrderList extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        let first = 20;
        let skip = 0;
        let next = "";
        let prev = "";
        if (Number(this.props.match.params.skip))
            skip = Number(this.props.match.params.skip);
        if (Number(this.props.match.params.first)) {
            first = Number(this.props.match.params.first);
            next = (skip + first).toString()
        }
        this.state = {
            query: "",
            loading: true,
            page: {totalCount: -1, next:"", prev:"", location:"archives", first:first, skip:skip},
            data: null,
            users: []
        };
        this.setQuery = this.setQuery.bind(this);
        this.updateMe = this.updateMe.bind(this);
        this.isMatch = this.isMatch.bind(this);
    }

    async updateMe() {
        client.query({query: GET_ARCHIVED_ORDERS, variables: {
            query:""
        }}).then((res) => {
            client.query({query: GET_USERS_BY_ID, variables: {id: res.data.orders.map((order: Order) => order.id)}}).then((res) => {
                this.setState({users: res.data.users})
            })
            let newPage = this.state.page;
            newPage.totalCount = res.data.orders.filter(this.isMatch).length;
            this.setState({page: newPage});
            client.query({query:GET_ARCHIVED_ORDERS, variables: {
                query:"", skip: this.state.page.skip, first: this.state.page.first
            }}).then((res) => {
                this.setState({data: res.data, loading: false})
            })
        })
    }

    componentDidMount(): void {
        this.updateMe();
    }

    setQuery(query:string) {
        let newPage = this.state.page;
        newPage.skip = 0;
        this.setState({query, page:newPage});
        this.updateMe();
    }

    isMatch(order: Order): boolean {
        if (this.state.query === "") return true
        let match = false;
        if (order.companyCode)
            if (order.companyCode.code.toLowerCase().includes(this.state.query.toLowerCase()))
                match = true;
        const user = this.state.users.find((user: User) => user.orders.find((ord) => ord.id === order.id))
        if (user) {
            // @ts-ignore
            if (user.orders) {
                // @ts-ignore
                if (user.orders.find((ordr) => order.id === ordr.id)) {
                    // @ts-ignore
                    if (user.name.toLowerCase().includes(this.state.query.toLowerCase()) || user.surname.toLowerCase().includes(this.state.query.toLowerCase()))
                        match = true;
                }
            }
        }
        if (order.nb.toLowerCase().includes(this.state.query.toLowerCase()))
            match = true;
        return match;
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (Loading);
        let ordersCell = this.state.data.orders.filter(this.isMatch).map((order:Order, key:number) =>
            <OrderListCell order={order} key={order.id}/>
        );
        return (
            TableContainer(
                "Liste des commandes:",
                "",
                [
                    "N° de commande",
                    "Quantité",
                    "Prix",
                    "Adresse de livraison",
                    "Date de livraison",
                    "Coordonnées du client",
                    "Code entreprise",
                    "Statut de la commande",
                    "Status",
                ],
                ordersCell,
                null,
                "null",
                "",
                this.setQuery,
                this.state.query,
                undefined,
                undefined,
                this.state.page
            )

        )
    }

}

export default ArchivedOrderList;
