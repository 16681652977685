import gql from 'graphql-tag';

const ALL_COMPANY_CODE = gql`
    query {
        companyCodes {
            id,
            code,
            address,
            postalCode,
            city
        }
    }
`;

export default ALL_COMPANY_CODE;
