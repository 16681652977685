import React from 'react';
import Button from '../components/Dashboard/Button'
import { useQuery } from '@apollo/react-hooks';
import {Loading} from "../components/Misc/Loading";
import DASHBOARD, {DASHBOARD_NEW} from "../graphql/queries/getDashboard";
import moment from "moment";
import manageError from "../components/Misc/Error";
import {PAGE_SIZE} from "../components/App";

export function DashboardContainer() {
    const { loading, error, data } = useQuery(DASHBOARD);
    let res_new = useQuery(DASHBOARD_NEW, {variables: {date: moment().startOf("day").format()}});
    if (loading) return (Loading);
    if (error) return (manageError(error));
    if (res_new.loading) return (Loading);
    if (res_new.error) return (manageError(error));
    let totalSell = 0;
    let newSell = 0;
    data.orders.forEach((order:any) => {totalSell += order.price});
    res_new.data.orders.forEach((order:any) => {newSell += order.price});
    let totalSellStr = totalSell.toString();
    totalSellStr += "€";
    return(
        <div className="dashboard">
            <div className="dashboard-content">
                <Button count={data.orders.length} what={"Commandes"} news={res_new.data.orders.length} symbol={"nouvelles"} flex={0} to={"/commandes/0/" + PAGE_SIZE}/>
                <Button count={data.products.length} what={"Produits"} news={0} symbol={""} flex={0} to={"/produits/type_ASC/0/" + PAGE_SIZE} />
                <Button count={data.users.length} what={"Utilisateurs"} news={0} symbol={"nouveaux"} flex={0} to={"/utilisateurs/0/" + PAGE_SIZE} />
            </div>
            <div className="dashboard-content">
                <Button count={parseFloat(totalSellStr).toFixed(2) + "€"} what={"Total des ventes"} news={parseFloat(newSell.toFixed(2))} symbol={"€"} flex={1} to={"/commandes/0/" + PAGE_SIZE} />
                <Button count={data.recipes.length} what={"Recettes"} news={0} symbol={""} flex={0} to={"/recettes/0/" + PAGE_SIZE} />
            </div>
        </div>
    )
}

export default DashboardContainer;
