import React, {useEffect, useState} from "react";
import TableContainer from "../../container/TableContainer";
import {useQuery} from "@apollo/react-hooks";
import GET_PRODUCTS, {GET_STOCK_PRODUCTS, GET_STOCK_PRODUCTS_2} from "../../graphql/queries/getAllProducts";
import {Loading} from "../Misc/Loading";
import LoadingCell from "../Misc/LoadingCell";
import {GET_FILE} from "../../graphql/queries/getFile";
import {Product} from "../../types/Products";
import {PrettyString} from "../Products/ProductCell";
import {client} from "../App";
import {Item, Order} from "../../types/Orders";

interface ProductStockProps {
    product: Product,
    count: number,
}

const ProductStock = (props: ProductStockProps) => {


    const { loading, error, data } = useQuery(GET_FILE, {variables:{id: props.product.photo.id}});
    if (loading)
        return (LoadingCell(["", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text"]));
    const photo = data.getFile;

    return (
        <div className="dash-table-row">
            <div className="dash-table-empty"/>
            <div className="dash-table-cell">{PrettyString(props.product.variety.name)}</div>
            <div className="dash-table-cell">{PrettyString(props.product.name)}</div>
            <div className="dash-table-cell table-cell-img">
                <img style={{maxHeight: 100}} alt={photo.File.filename} src={"data:" + photo.File.mimeType + ";base64," + photo.data} />
            </div>
            <div className="dash-table-cell">{PrettyString(props.product.unit)}</div>
            <div className="dash-table-cell">{props.count}</div>
        </div>
    )
}

const Stocks = (props: any) => {

    const [page, setPage] = useState({totalCount: -1, next: "", prev: "", location: "stocks", first: Number(props.match.params.first), skip: Number(props.match.params.skip)});
    const [query, setQuery] = useState("");
    //const {loading, data, error} = useQuery(GET_STOCK_PRODUCTS, {variables: {orderBy: "stock_DESC"}});
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>([]);
    const [ComputedData, setComputedData] = useState<Array<{count: number, product: Product}>>([]);

    const updateData = () => {
        client.query({query: GET_STOCK_PRODUCTS_2, variables: {query: query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()}}).then((res) => {
            const productsItems: Array<Array<Item>> = res.data.orders.map((order:Order) => {
                return order.items
            });
            const products: Array<{count: number, product: Product}> = []
            //console.log(productsItems)
            productsItems.forEach((el) => {
                el.forEach((il) => {
                    //console.log(il.product)
                    const sameProduct = products.find((item) => item.product.id === il.product.id)
                    if (sameProduct)
                        sameProduct.count += il.count
                    else
                        products.push({count: il.count, product: il.product})
                })
            })
            setComputedData(products);
            let tmp = page;
            tmp.totalCount = products.length;
            setPage(tmp);
            setLoading(false);
            //console.log(products);
        })

        // OLD
        //client.query({query: GET_STOCK_PRODUCTS, variables: {query: query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()}}).then((res) => {
        //    let tmp = page;
        //    tmp.totalCount = res.data.products.length;
        //    setPage(tmp);
        //    client.query({
        //        query: GET_STOCK_PRODUCTS, variables: {
        //            query: query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(),
        //            skip: page.skip, first: page.first
        //        }
        //    }).then((res) => {
        //        setData(res.data)
        //        setLoading(false);
        //    })
        //});
    }

    useEffect(() => {
        updateData();
    }, [])

    if (loading) return Loading

    const search = (query: string) => {
        const tmp = page;
        tmp.skip = 0
        setPage(tmp)
        setQuery(query)
        updateData();
    }

    return (
        TableContainer(
            "Stocks des produits:",
            "",
            [
                "",
                "Variété",
                "Nom",
                "Photo",
                "Unité",
                "Stock à acheter",
            ],
            ComputedData.filter(el => el.product.name.includes(query) || el.product.variety.name.includes(query)|| el.product.verbose_type.includes(query)).map((el) => <ProductStock product={el.product} count={el.count}/>),
            //data.products.filter((el: any) => el.stcok !== 0).map((prod: Product) => <ProductStock product={prod}/>),
            null,
            "",
            undefined,
            search,
            query,
            [],
            undefined,
            page
            )
    )
}

export default Stocks;
