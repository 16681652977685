import React from "react";
import TableContainer from "../../container/TableContainer";
import {client} from "../App";
import ALL_VARIETIES from "../../graphql/queries/getAllVariety";
import {Variety} from "../../types/Variety";
import {Loading} from "../Misc/Loading";
import VarietyListCell from "./VarietyListCell";

interface State {
    loading: boolean
    varieties: Array<Variety>
}

class VarietyList extends React.Component<object, State> {

    constructor(props:object) {
        super(props);
        this.state = {
            loading: true,
            varieties: []
        }
    }

    componentDidMount(): void {
        client.query({query: ALL_VARIETIES}).then((data: any) => {
            this.setState({loading: false, varieties: data.data.varieties})
        }).catch((error) => {
            //console.log(error);
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (Loading);
        let varietiesCell = this.state.varieties.map((variety:Variety) =>
            <VarietyListCell variety={variety} key={variety.id} />
        );
        return (
            TableContainer(
                "Liste des variétés:",
                "",
                [
                    "",
                    "Type",
                    "Nom",
                    "Photo",
                    "Couleur"
                ],
                varietiesCell,
                null,
                "",
                "",
                "",
                "",
                [],
                true
            )
        );
    }
}

export default VarietyList;
