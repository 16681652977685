import React from "react";
import DashboardTable from "../Dashboard/DashboardTable";
import AddProductForm from "./AddProductForm";
import {Product} from "../../types/Products";

export function AddProductComponent(product?: Product) {
    return (
            <DashboardTable header={[
                "Type",
                "Variété",
                "Nom du produit",
                "Photo du produit",
                "Prix de vente",
                "Unité",
                "Provenance",
                "Description",
                "Idées recettes",
                "Visible"
            ]} data={AddProductForm(product)} popup={true}/>
    )
}

export default AddProductComponent;