import React, {Component} from 'react';
import {Companycode} from '../../types/Companycode';
import {client} from '../App';
import REM_COMPANY_CODE from '../../graphql/mutations/RemoveCompanyCode';
import {PrettyString} from "../Products/ProductCell";

interface companyCodeProps {
    code: Companycode
}

class CompanyCodeListCell extends Component<companyCodeProps> {
    constructor(props: companyCodeProps) {
        super(props);
        this.handleRemove = this.handleRemove.bind(this)
    }

    handleRemove() {
        client.mutate({
            mutation: REM_COMPANY_CODE,
            variables: {id: this.props.code.id}
        }).then(() => {window.location.reload()})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="dash-table-row">
                <div className="dash-table-empty"><button onClick={() => {this.handleRemove()}} className="dash-table-button">X</button></div>
                <div className="dash-table-cell" style={{fontSize: ".7vw", wordBreak: "break-word"}}>{this.props.code.code}</div>
                <div className="dash-table-cell" style={{fontSize: ".7vw", wordBreak: "break-word"}}>{this.props.code.address}</div>
                <div className="dash-table-cell" style={{fontSize: ".7vw", wordBreak: "break-word"}}>{PrettyString(this.props.code.city)}</div>
                <div className="dash-table-cell" style={{fontSize: ".7vw", wordBreak: "break-word"}}>{this.props.code.postalCode}</div>
            </div>
        )
    }
}

export default CompanyCodeListCell;
