import React, {Component} from 'react';
import '../../styles/Dashboard.css'
import { Link } from "react-router-dom";

type DashboardButtonProps = {
    count: number|string
    what: string
    news: number
    symbol: string
    flex: number
    to: string
}

export class DashboardButtton extends Component<DashboardButtonProps> {
    render() {
        return (
            <div className="dashboard-button" style={{flexBasis: this.props.flex > 0 ? "53.5%" : "" }}>
                <Link className="button-link dashboard-button" style={{flexBasis: this.props.flex > 0 ? "53.5%" : ""}} to={this.props.to}>
                <div className="button-count">{this.props.count}</div>
                <div className="button-what">{this.props.what}</div>
                {this.props.news > 0 &&
                <div className="button-news">+{this.props.news} {this.props.symbol}</div>
                }
                </Link>
            </div>
        )
    }
}

export default DashboardButtton;
