import gql from "graphql-tag";

const GET_RECIPE = gql`
    query getSingleRecipe($id: ID!) {
        recipe(where: {id: $id}) {
            id
            title
            photo {
                id
            }
            time
            number_of_people
            author
            visible
        }
    }
`;

export default GET_RECIPE;