import React from "react";
import GET_RECIPES from "../../graphql/queries/getAllRecipes";
import TableContainer from "../../container/TableContainer";
import {Loading} from "../Misc/Loading";
import {RecipesCell} from "../../types/Recipes";
import RecipesListCell from "./RecipesListCell";
import Pagination from '../../types/Pagination';
import {client} from '../App';
import {PrettyString} from "../Products/ProductCell";

interface State {
    loading: boolean
    query: string
    page: Pagination
    data: any
}

class RecipesList extends React.Component<any, State> {
    constructor(props:any) {
        super(props);
        let first = 2;
        let skip = 0;
        let next = "";
        let prev = "";
        if (Number(this.props.match.params.skip))
            skip = Number(this.props.match.params.skip);
        if (Number(this.props.match.params.first)) {
            first = Number(this.props.match.params.first);
            next = (skip + first).toString()
        }
        this.state = {
            query: "",
            loading: true,
            page: {totalCount: -1, next:"", prev:"", location:"recettes", first:first, skip:skip},
            data: null
        };
        this.setQuery = this.setQuery.bind(this);
        this.updateMe = this.updateMe.bind(this);
    }

    updateMe(): void {
        client.query({query: GET_RECIPES, variables: {query: PrettyString(this.state.query.toLowerCase())}}).then((res) => {
            let newPage = this.state.page;
            newPage.totalCount = res.data.recipes.length;
            this.setState({page:newPage});
            client.query({query: GET_RECIPES, variables: {
                query:PrettyString(this.state.query.toLowerCase()), skip: this.state.page.skip, first: this.state.page.first
            }}).then((res) => {
                this.setState({data: res.data, loading: false})
            })
        })
    }

    componentDidMount(): void {
        this.updateMe();
    }

    setQuery(query:string) {
        let newPage = this.state.page;
        newPage.skip = 0;
        this.setState({query, page:newPage});
        this.updateMe();
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (Loading);
        let recipesCell = this.state.data.recipes.map((recipe:RecipesCell, key:number) =>
            <RecipesListCell recipe={recipe} key={key}/>
        );

        return (
            TableContainer(
                "Liste des recettes:",
                "+ Ajouter une recette",
                ["", "Nom de la recette", "Photo", "Temps de préparation", "Nombre de personnes", "Auteur", "Recette visible sur l'application"],
                recipesCell,
                null,
                "",
                "/recettes/nouvelle",
                this.setQuery,
                this.state.query,
                undefined,
                undefined,
                this.state.page
            )
        )
    }
}

/*
export function RecipesList() {
    const [query, setQuery] = React.useState("");
    let { loading, error, data } = useQuery(GET_RECIPES, {variables: {query: PrettyString(query)}});
    if (loading)
        return (Loading);
    if (error)
        return (manageError());
    let recipesCell = data.recipes.map((recipe:RecipesCell, key:number) =>
        <RecipesListCell recipe={recipe} key={key}/>
    );
    return (
        TableContainer(
            "Liste des recettes:",
            "+ Ajouter une recette",
            ["", "Nom de la recette", "Photo", "Temps de préparation", "Nombre de personnes", "Auteur", "Recette visible sur l'application"],
            recipesCell,
            null,
            "",
            "/recettes/nouvelle",
            setQuery,
            query
        )
    )
}*/

export default RecipesList;
