import gql from "graphql-tag";

const GET_USER_BY_ORDER = gql`
    query GetUserByOrder($id: ID!) {
        users(where: {orders_some: {id: $id}}) {
            id
            address
            postalCode
            city
            name
            surname
            phone
            email
        }
    }
`;

export default GET_USER_BY_ORDER;
