import gql from "graphql-tag";

export const NEW_RECIPE = gql`
    mutation createRecipe($title: String!, $photo: ID!, $time: Int!, $number_of_people: Int!, $author: String!, $visible: Boolean!, $ingredients: [String!]!, $description: String!) {
        createRecipe(data:{
            title: $title,
            photo: {connect: {id: $photo}},
            time: $time,
            number_of_people: $number_of_people,
            author: $author,
            visible: $visible,
            ingredients: {set: $ingredients},
            description: $description
        }) {
            id
        }
    }
`;

export default NEW_RECIPE;