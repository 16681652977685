import gql from "graphql-tag";

const GET_PRODUCT = gql`
    query getSingleProduct($id: ID!) {
        product(where: {id: $id}) {
            type,
            variety {
                name,
                id
            },
            name,
            photo {
                id
            },
            price,
            promotion,
            unit,
            origin,
            description,
            recipe {
                title,
                id
            },
            visible,
            id
        }
    }
`;

export default GET_PRODUCT;