import gql from "graphql-tag";

const UPDATE_RECIPE = gql`
    mutation updateRecipe($data: Product, $id: ID) {
        updateRecipe(
            data: $data,
            where: {
                id:$id
            }
        ) {
            id
        }
    }
`;

export const UPDATE_RECIPE_VISIBLE = gql`
    mutation updateRecipeVisible($visible: Boolean, $id: ID) {
        updateRecipe(
            data: {
                visible: $visible
            }, where: {
                id:$id
            }
        ) {
            id
        }
    }
`;

export default UPDATE_RECIPE;