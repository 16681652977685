import React, {Component} from 'react'
import PopupContainer from "../../container/PopupContainer";
import "../../styles/Lists.css"
import { Redirect } from "react-router-dom"

type DashboardHeaderListProps = {
    label: string
    button: string
    popupComponent: any
    popupLabel: string
    redirect?: string
    search?: any
    query?: string
    searchBar?: boolean
    rightComponent?: any
}

interface DashboardHeaderListState {
    popup: boolean,
    redirect: boolean,
    query: string,
}

export class DashboardHeaderList extends Component<DashboardHeaderListProps, DashboardHeaderListState> {
    constructor(props:DashboardHeaderListProps) {
        super(props);
        this.state = {
            popup: false,
            redirect: false,
            query: props.query ? props.query : ""
        };
        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
    }

    closePopup() {
        this.setState({popup:false})
    }

    redirect() {
        if (this.state.redirect && this.props.redirect)
            return (<Redirect to={this.props.redirect} />)
    }

    search(e:any) {
        this.props.search(e.target.value)
    }

    handleChange(e:any) {
        this.setState({query:e.target.value});
    }

    handleEnter(e:any) {
        if (e.keyCode === 13)
            this.props.search(e.target.value);
    }

    render() {
        let popup = <PopupContainer component={this.props.popupComponent} label={this.props.popupLabel} onClick={this.closePopup.bind(this)} />;
        return (
            <>
            {this.state.popup && popup}
            {this.redirect()}
            <div className="dheader-container">
                <h1 className="dheader-label">{this.props.label}</h1>
                {this.props.rightComponent}
                {this.props.button.length !== 0 &&
                <button className="right dheader-button" onClick={() => {
                    (this.props.popupComponent) ?
                        (this.setState({popup: true})) :
                        (this.setState({redirect: true}))
                    ;
                }}>
                    {this.props.button}
                </button>
                }
                {!this.props.searchBar &&
                <input value={this.state.query} onChange={this.handleChange} onKeyUp={this.handleEnter} onBlur={this.search} placeholder="Recherche" className="input-search" type="text"/>
                }
            </div>
            </>
        )
    }
}


export default DashboardHeaderList;
