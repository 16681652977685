import React from 'react';
import { AuthContext } from "./App";
import Navbar from "./Navbar/Navbar";
import LoginComponent from "./Login/LoginComponent";
import { Route } from 'react-router-dom';

export function AppHeader() {
    return (
        <AuthContext.Consumer>
            {context => (
                <>
                    {(context.token) ?
                        (<Navbar />)
                        :
                        (<Route path={"/connexion"} exact component={LoginComponent}/>)
                    }

                </>
            )}
        </AuthContext.Consumer>
    );
}