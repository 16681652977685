import React, { Component } from 'react'
import { Route, Link } from "react-router-dom";
import '../../styles/navbar.css'

type NavbarLinkProps = {
    label: string,
    activeOnlyWhenExact: boolean,
    notify: boolean,
    to: string,
}

export class NavbarLink extends Component<NavbarLinkProps> {
    render() {
        return (
            <Route
                path={this.props.to}
                exact={this.props.activeOnlyWhenExact}
                children={({ match }) => (
                    <li className={"nav-link" + (match ? " nav-active" : "") + (this.props.notify ? " nav-notif" : "")}>
                        <Link to={this.props.to} className="nav-a">
                            {this.props.label}
                        </Link>
                    </li>
                )}
            />
        )
    }
}

export default NavbarLink;