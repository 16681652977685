import gql from "graphql-tag";

export const GET_VARIETIES_FORM = gql`
    query VarForm($type: ProductType) {
        varieties(where: {type: $type}) {
            id,
            name
        }
    }
`;

const ALL_VARIETIES = gql`
    query {
        varieties {
            id,
            name,
            type,
            photo {id},
            main_color
        }
    }
`;

export default ALL_VARIETIES;
