import React from "react";
import "../../styles/Forms.css"
import photo from "../../img/photo.png"
import { client } from "../App";
import UPLOAD_FILE from "../../graphql/mutations/UploadFile";
import NEW_VARIETY from "../../graphql/mutations/NewVariety";

interface Variety {
    name: string
    type: string
    photo: string
    main_color: string
}

interface Props {
    data: Variety
}

interface State {
    data: Variety
    valid: boolean
    upBG: any
    file: any
    loading: boolean
    fileChanged: boolean
}

export class AddVarietyFormDesign extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.state = {
            data: this.props.data,
            valid: false,
            upBG: null,
            file: null,
            loading: true,
            fileChanged: false
        }
    }

    getBase64(file:Blob) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let tmp = this.state.data;
        let cb = this.setState.bind(this);
        let val = this.validateData.bind(this);
        reader.onload = function () {
            if (typeof reader.result === "string") {
                tmp.photo = reader.result;
                if (tmp.photo.length)
                    cb({upBG: tmp.photo, fileChanged: true});
                else
                    cb({upBG: photo});
                cb({data: tmp});
                val();
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    validateData() {
        let data = this.state.data;
        if (data.name.length && data.photo.length && data.main_color.length)
            {this.setState({valid: true});}
        else
            this.setState({valid: false});
    }

    handleChange(e:any) {
        let tmp = this.state.data;
        e.target.name === "type" && (tmp.type = e.target.value);
        e.target.name === "photo" && e.target.files[0] && this.getBase64(e.target.files[0]);
        e.target.name === "photo" && e.target.files[0] && this.setState({file: e.target.files[0]});
        e.target.name === "name" && (tmp.name = e.target.value);
        e.target.name === "main_color" && (tmp.main_color = e.target.value);
        this.setState({data: tmp});
        this.validateData();
    }

    handleSubmit(e:any) {
        e.preventDefault();
        let tmp = this.state.data;

        let file = new FormData();
        file.append(this.state.file.name, URL.createObjectURL(this.state.file));
        client.mutate({
            variables: {file: this.state.file},
            context: {hasUpload: true},
            mutation: UPLOAD_FILE,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            client.mutate({
                variables: {
                    name: tmp.name.toLowerCase(),
                    photo: result.data.uploadSingleFile.id,
                    main_color: tmp.main_color,
                    type: tmp.type
                },
                mutation: NEW_VARIETY,
            }).then(result => { window.location.reload() }).catch(error => { //console.log(error)
                });
        }).catch(error => {//console.log(error)
            });
    }

    render() {
        return (
            <>
                <form className="manage-flex" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Nom de la variété:</h4>
                        <input value={this.state.data.name} name="name" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Type de la variété:</h4>
                        <select id="type" defaultValue={this.props.data.type} name="type" onChange={e => this.handleChange(e)} className="input-recipe" style={{resize: "none"}}>
                            <option disabled value="" style={{display: "none"}}/>
                            <option value="FRUIT">Fruit</option>
                            <option value="VEGETABLE">Légume</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Couleur de la variété:</h4>
                    <input type="color" value={this.state.data.main_color} name="main_color" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Télécharger une photo:</h4>
                        <input id="photo-input" name="photo" onChange={e => this.handleChange(e)} type="file" style={{display:"none"}}/>
                        <input value={this.state.data.photo} readOnly style={{display:"none"}}/>
                        <label htmlFor="photo-input">
                            <img src={photo} alt={"upload"}/>
                        </label>
                    </div>
                    <div className="form-row" style={this.state.upBG ? {} : {display:"none"}}>
                        <div className="form-col">
                            <h4 className="input-recipe-label">Aperçu de la photo:</h4>
                            <img alt="" className="form-img" src={this.state.upBG}/>
                        </div>
                    </div>
                    <input disabled={!this.state.valid} type="submit" id="addVariety" style={{display: "none"}} />
                </form>
                <label className={this.state.valid ? "button-recipe dheader-button" : "button-recipe dheader-button dheader-button-disabled "} htmlFor={"addVariety"}>Ajouter la variété à la liste</label>
            </>
        )
    }
}

export function AddVarietyForm() {
    let initial_values:Variety = {
        name: "",
        type: "",
        photo: "",
        main_color: "#F7F7F7"
    };
    return(<AddVarietyFormDesign data={initial_values} />)
}

export default AddVarietyForm;
