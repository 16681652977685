import React, {useState} from "react";
import { Product } from "../../types/Products";
import { GET_FILE } from "../../graphql/queries/getFile";
import {useQuery} from "@apollo/react-hooks";
import {client} from "../App";
import {UPDATE_PRODUCT_VISIBLE} from "../../graphql/mutations/UpdateProduct";
import PopupContainer from "../../container/PopupContainer";
import AddProductComponent from "./AddProductComponent";
import LoadingCell from "../Misc/LoadingCell";
import manageError from "../Misc/Error";
import ProductPromotion from "./ProductPromotion";
import "../../styles/DashboardTable.css"
import GET_PRODUCT from "../../graphql/queries/getSingleProduct";
import REM_PRODUCT from "../../graphql/mutations/RemoveProduct";

interface ProductCellProps {
    product: Product
    key: number
}

export const PrettyString = (str: string):string => {
    let res = "";
    str.split(' ').forEach((val, idx) => {
        res += (val.charAt(0).toUpperCase() + val.slice(1));
        if (idx + 1 !== str.split(' ').length) res += " ";
    });
    return res
};

const ConfirmPopup = (setPopup:any, popup:boolean, handleRemove: (userId: string) => void, userId: string) : React.ReactElement => {
    return(
        <div className="confirm-container">
            <div className="confirm-content">
                <div className="confirm-box">
                    <div onClick={() => setPopup(!popup)} className="confirm-close">✕</div>
                    <div style={{margin: "30px"}}>Confirmez la suppression du produit ?</div>
                    <div className="confirm-button-container">
                        <div onClick={() => handleRemove(userId)} className="confirm-button-yes">Oui</div>
                        <div onClick={() => setPopup(!popup)} className="confirm-button-no">Non</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const handleRemove = (productId:string) => {
    //console.log(productId);
    client.mutate({
        mutation: REM_PRODUCT,
        variables: {id: productId},
        refetchQueries: [{
            query: GET_PRODUCT,
            variables: {id: productId}
        }]}).then(res => {
            window.location.reload();
    })
};

export const ProductCell = (props: ProductCellProps) => {

    const [visible, setVisible] = useState(props.product.visible);
    const [isPopup, setPopup] = useState(false);
    let [confirm, setConfirm] = useState(false);
    let { loading, error, data } = useQuery(GET_FILE, {variables:{id: props.product.photo.id}});
    if (loading)
        return (LoadingCell(["", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-switch"]));
    if (error)
        manageError(error);
    let photo = data.getFile;
    let recipes = props.product.recipe.map((recipe, key) =>
        <div key={key}>{recipe.title}</div>
    );

    let handleChange = (e:any) => {
        client.mutate({
            mutation: UPDATE_PRODUCT_VISIBLE,
            variables: {visible: !visible, id:props.product.id},
            refetchQueries: [{query: GET_PRODUCT, variables: {id: props.product.id}}]
        }).then((result) => {
                setVisible(!visible);
        })
    };

    const closePopup = (e:any) => {
        setPopup(false);
    };


    let confirmComponent = ConfirmPopup(setConfirm, confirm, handleRemove, props.product.id);

    let popup = <PopupContainer component={AddProductComponent(props.product)} label={"Modifier " + PrettyString(props.product.name) + ":"} onClick={closePopup} />;
    return (
        <>
        {isPopup && popup}
        {confirm && confirmComponent}
        <div className="dash-table-row">
            <div className="dash-table-empty">
                <img src="/img/edit.png" alt="edit" className="cell-edit" onClick={e => setPopup(true)}/>
                <button onClick={(e) => {setConfirm(!confirm)}} className="dash-table-button">X</button>
            </div>
            <div className="dash-table-cell">{props.product.type === "FRUIT" ? "Fruit" : "Légume"}</div>
            <div className="dash-table-cell">{PrettyString(props.product.variety.name)}</div>
            <div className="dash-table-cell">{PrettyString(props.product.name)}</div>
            <div className="dash-table-cell table-cell-img">
                <img alt={photo.File.filename} src={"data:" + photo.File.mimeType + ";base64," + photo.data} />
            </div>
            <div className="dash-table-cell" style={{flexDirection: "column"}}>
                {props.product.price}€
                <ProductPromotion id={props.product.id} promotion={props.product.promotion} price={props.product.price}/>
            </div>
            <div className="dash-table-cell">{props.product.unit}</div>
            <div className="dash-table-cell">{props.product.origin}</div>
            <div className="dash-table-cell">{props.product.description}</div>
            <div className="dash-table-cell" style={{flexDirection: "column"}}>{recipes}</div>
            <div className="dash-table-cell">
                <div className="onoffswitch">
                    <input onChange={handleChange} checked={visible} type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id={"myonoffswitch" + props.product.id} />
                    <label className="onoffswitch-label" htmlFor={"myonoffswitch" + props.product.id} />
                </div>
            </div>
        </div>
        </>
    );
};

export default ProductCell;
