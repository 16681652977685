import TableContainer from "../../container/TableContainer";
import GET_ORDERS from "../../graphql/queries/getAllOrders";
import {Loading} from "../Misc/Loading";
import OrderListCell from "./OrderListCell";
import * as React from "react";
import {Order} from "../../types/Orders";
import Pagination from '../../types/Pagination';
import { client } from "../App";

interface State {
    loading: boolean
    query: string
    page: Pagination
    data: any
}

class OrderList extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        let first = 2;
        let skip = 0;
        let next = "";
        let prev = "";
        if (Number(this.props.match.params.skip))
            skip = Number(this.props.match.params.skip);
        if (Number(this.props.match.params.first)) {
            first = Number(this.props.match.params.first);
            next = (skip + first).toString()
        }
        this.state = {
            query: "",
            loading: true,
            page: {totalCount: -1, next:"", prev:"", location:"commandes", first:first, skip:skip},
            data: null
        };
        this.setQuery = this.setQuery.bind(this);
        this.updateMe = this.updateMe.bind(this);
    }

    updateMe(): void {
        client.query({query: GET_ORDERS, variables: {
            query:this.state.query.toLowerCase()
        }}).then((res) => {
            let newPage = this.state.page;
            newPage.totalCount = res.data.orders.length;
            this.setState({page: newPage});
            client.query({query:GET_ORDERS, variables: {
                query:this.state.query.toLowerCase(), skip: this.state.page.skip, first: this.state.page.first
            }}).then((res) => {
                this.setState({data: res.data, loading: false})
            })
        })
    }

    componentDidMount(): void {
        this.updateMe();
    }

    setQuery(query:string) {
        let newPage = this.state.page;
        newPage.skip = 0;
        this.setState({query, page:newPage});
        this.updateMe();
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (Loading);
        let ordersCell = this.state.data.orders.map((order:Order, key:number) =>
            <OrderListCell order={order} key={order.id}/>
        );
        return (
            TableContainer(
                "Liste des commandes:",
                "",
                [
                    "N° de commande",
                    "Quantité",
                    "Prix",
                    "Adresse de livraison",
                    "Date de livraison",
                    "Coordonnées du client",
                    "Code entreprise",
                    "Statut de la commande",
                    "Status",
                ],
                ordersCell,
                null,
                "null",
                "",
                this.setQuery,
                this.state.query,
                undefined,
                undefined,
                this.state.page
            )

        )
    }

}

export default OrderList;
