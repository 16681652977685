import React, {ChangeEvent, useEffect, useState} from "react";
import {client} from "../App";
import ALL_COMPANY_CODE from "../../graphql/queries/getAllCompanyCode";
import {CompanyCode, DeliveryLocation, Order} from "../../types/Orders";
import ALL_DELIVERY_LOCATIONS from "../../graphql/queries/getAllDeliveryLocation";
import {GET_SALES, GET_SALES_CODES, GET_SALES_NO_CODES} from "../../graphql/queries/getAllOrders";
import moment from "moment";
import DashboardTable from "../Dashboard/DashboardTable";
import OrderListCell from "../Order/OrderListCell";
import {GET_USERS_BY_ID} from "../../graphql/queries/getAllUsers";
import {User} from "../../types/Users";
import ExportButton from "../Misc/ExportButton";
import {Loading} from "../Misc/Loading";

const Sales = () => {

    const [startDate, setStartDate] = useState("2020-01-01");
    const [stopDate, setStopDate] = useState(moment().format("YYYY-MM-DD"));
    const [allSales, setAllSales] = useState(true);
    const [companyCodeOnly, setCompanyCodeOnly] = useState(false);
    const [noCompanyCodeOnly, setNoCompanyCodeOnly] = useState(false);
    const [companyCodes, setCompanyCodes] = useState<Array<CompanyCode>>([]);
    const [cities, setCities] = useState<Array<DeliveryLocation>>([]);
    const [selectedCodes, setSelectedCodes] = useState<Array<string>>([]);
    const [sortByCity, setSortByCity] = useState(false);
    const [selectedCities, setSelectedCities] = useState<Array<string>>([]);
    const [sales, setSales] = useState<Array<Order>>([]);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        client.query({query: ALL_COMPANY_CODE}).then((data: any) => {
            setCompanyCodes(data.data.companyCodes)
        }).catch((err) => {
            //console.log(err);
        })
        client.query({query: ALL_DELIVERY_LOCATIONS}).then((res) => {
            setCities(res.data.deliveryLocations)
        }).catch((err) => {
            //console.log(err);
        })
        handleSearch()
    }, [])

    useEffect(() => {
        //handleSearch()
    }, [startDate, stopDate, allSales, companyCodeOnly, noCompanyCodeOnly, companyCodes, cities, selectedCodes, sortByCity, selectedCities])


    const handleSearch = () => {
        const variables = {startDate, stopDate}
        setLoading(true);
        if (sortByCity) {
            // @ts-ignore
            variables["cities"] = selectedCities;
        }
        if (companyCodeOnly) {
            // @ts-ignore
            variables["codes"] = selectedCodes;
        }
        if (companyCodeOnly && selectedCodes.length !== 0) {
            client.query({query: GET_SALES_CODES, variables: variables}).then((res) => {
                client.query({query: GET_USERS_BY_ID, variables: {id: res.data.orders.map((order: Order) => order.id)}}).then((res) => {
                    setUsers(res.data.users)
                })
                setSales(res.data.orders)
                setLoading(false);
            })
        } else {
            client.query({query: GET_SALES, variables: variables}).then((res) => {
                client.query({query: GET_USERS_BY_ID, variables: {id: res.data.orders.map((order: Order) => order.id)}}).then((res) => {
                    setUsers(res.data.users)
                })
                if (noCompanyCodeOnly)
                    setSales(res.data.orders.filter((order: Order) => !order.companyCode))
                else if (companyCodeOnly && selectedCodes.length === 0)
                    setSales(res.data.orders.filter((order: Order) => order.companyCode))
                else
                    setSales(res.data.orders)
                setLoading(false);
            })
        }
        setPage(1)
    }

    const getTotal = (type: "money" | "user") => {
        if (type === "money") {
            let i = 0;
            sales.forEach((sale) => i += (sale.price + sale.shipping_price))
            return i.toFixed(2);
        }
        if (type === "user") {
            let i = 0;
            //sales.forEach((sale) => sale.)
            return i.toFixed(2);
        }
        return 0;
    }

    const handleFilterChange = (name: "all" | "code" | "city" | "personal", e: ChangeEvent<HTMLInputElement>) => {
        switch (name) {
            case "all":
                if (!allSales) {
                    setAllSales(true);
                    setCompanyCodeOnly(false);
                    setSelectedCities([])
                    setSelectedCodes([])
                    setNoCompanyCodeOnly(false)
                    setSortByCity(false);
                }
                break;
            case "code":
                if (companyCodeOnly) {
                    setCompanyCodeOnly(false)
                } else {
                    setCompanyCodeOnly(true);
                    setNoCompanyCodeOnly(false);
                    setAllSales(false);
                }
                break;
            case "city":
                if (!sortByCity) {
                    setAllSales(false);
                }
                setSortByCity(!sortByCity)
                break;
            case "personal":
                if (noCompanyCodeOnly) {
                    setNoCompanyCodeOnly(false)
                } else {
                    setCompanyCodeOnly(false)
                    setSelectedCodes([])
                    setNoCompanyCodeOnly(true)
                    setAllSales(false)
                }
                break;
        }
    }

    const isMatch = (order: Order): boolean => {
        if (search === "") return true
        let match = false;
        if (order.companyCode)
            if (order.companyCode.code.toLowerCase().includes(search.toLowerCase()))
                match = true;
        const user = users.find((user: User) => user.orders.find((ord) => ord.id === order.id))
        if (user) {
            // @ts-ignore
            if (user.orders) {
                // @ts-ignore
                if (user.orders.find((ordr) => order.id === ordr.id)) {
                    // @ts-ignore
                    if (user.name.toLowerCase().includes(search.toLowerCase()) || user.surname.toLowerCase().includes(search.toLowerCase()))
                        match = true;
                }
            }
        }
        if (order.nb.toLowerCase().includes(search.toLowerCase()))
            match = true;
        return match;
    }

    const getFilterString = () => {
        return (
            <>
                {`Ventes du ${moment(startDate).format("DD/MM/YYYY")} au ${moment(stopDate).format("DD/MM/YYYY")}`}
                {companyCodeOnly ? <><br/><br/>{"uniquement avec code entreprise:" + selectedCodes.map((code) => companyCodes.find((el) => el.id === code)?.code).join(" ou ")}</> : ""}
                {noCompanyCodeOnly ? <><br/><br/>uniquement les particuliers</> : ""}
                {sortByCity ? <><br/><br/>uniquement ces villes: {selectedCities.map((city) => cities.find((el) => el.id === city)?.locations).join(" ou ")}</> : ""}
            </>
        )
    }

    const getPages = () => {
        const pages: Array<any> = []
        for (let i = 1; i <= Math.ceil(sales.length / 20); i++) {
            pages.push(<option selected={page === i} value={i}>Page {i}</option>)
        }
        return pages;
    }

    let ordersCell = sales.filter((order) => isMatch(order)).slice((page - 1) * 20, page * 20).map((order: Order, key: number) =>
        <OrderListCell minimize order={order} key={order.id}/>
    );

    return (
        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 50, marginBottom: 50}}>
                <div>
                    <div>Sélectionnez la période</div>
                    <h4 style={{width: '100%'}} className="input-recipe-label">Date de début:</h4>
                    <input style={{marginTop: 5, width: '100%'}} className="input-recipe" type={"date"} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                    <h4 style={{width: '100%'}} className="input-recipe-label">Date de fin:</h4>
                    <input style={{marginTop: 5, width: '100%'}} className="input-recipe" type={"date"} value={stopDate} onChange={(e) => setStopDate(e.target.value)}/>
                </div>
                <div>
                    <div>Sélectionnez le type de ventes recherchées</div>
                    <div style={{display: "flex"}}>
                        <input style={{marginTop: 5}} type={"checkbox"} checked={allSales} onChange={(e) => handleFilterChange("all", e)}/>
                        <h4 style={{width: '100%'}} className="input-recipe-label">Toutes les ventes</h4>
                    </div>
                    <div style={{display: "flex"}}>
                        <input style={{marginTop: 5}} type={"checkbox"} checked={companyCodeOnly} onChange={(e) => handleFilterChange("code", e)}/>
                        <h4 style={{width: '100%'}} className="input-recipe-label">Ventes par code entreprise</h4>
                    </div>
                    {companyCodeOnly &&
                    <select value={selectedCodes} onChange={(e) => setSelectedCodes(Array.from(e.target.selectedOptions, item => item.value))} multiple>
                        {companyCodes.sort(((a, b) => a.code < b.code ? -1 : 1)).map((code) => <option value={code.id}>{code.code}</option>)}
                    </select>
                    }
                    <div style={{display: "flex"}}>
                        <input style={{marginTop: 5}} type={"checkbox"} checked={noCompanyCodeOnly} onChange={(e) => handleFilterChange("personal", e)}/>
                        <h4 style={{width: '100%'}} className="input-recipe-label">Ventes des particuliers</h4>
                    </div>
                    <div style={{display: "flex"}}>
                        <input style={{marginTop: 5}} type={"checkbox"} checked={sortByCity} onChange={(e) => handleFilterChange("city", e)}/>
                        <h4 style={{width: '100%'}} className="input-recipe-label">Ventes par villes</h4>
                    </div>
                    {sortByCity &&
                    <select value={selectedCities} onChange={(e) => setSelectedCities(Array.from(e.target.selectedOptions, item => item.value))} multiple>
                        {cities.sort(((a, b) => a.locations < b.locations ? -1 : 1)).map((code) => <option value={code.id}>{code.locations}</option>)}
                    </select>
                    }
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                <div style={{padding: 50, border: "1px black solid", display: "flex", flex: 1, marginLeft: 50, marginRight: 50, justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontSize: 20, fontWeight: "bold"}}>
                        CA: {getTotal("money")}€
                    </div>
                    {startDate && stopDate &&
                    <div>
                        Du {moment(startDate).format("DD/MM/YYYY")} au {moment(stopDate).format("DD/MM/YYYY")}
                    </div>
                    }
                </div>
                <div style={{padding: 50, border: "1px black solid", display: "flex", flex: 1, marginLeft: 50, marginRight: 50, justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontSize: 20, fontWeight: "bold"}}>
                        Nombre de vente: {sales.length}
                    </div>
                    {startDate && stopDate &&
                    <div>
                        Du {moment(startDate).format("DD/MM/YYYY")} au {moment(stopDate).format("DD/MM/YYYY")}
                    </div>
                    }
                </div>
                <div style={{padding: 50, border: "1px black solid", display: "flex", flex: 1, marginLeft: 50, marginRight: 50, justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontSize: 20, fontWeight: "bold"}}>
                        Utilisateurs concernés: {users.length}
                    </div>
                    {startDate && stopDate &&
                    <div>
                        Du {moment(startDate).format("DD/MM/YYYY")} au {moment(stopDate).format("DD/MM/YYYY")}
                    </div>
                    }
                </div>
            </div>
            <div style={{display: "flex", flex: 1, justifyContent: "space-around", marginTop: 50}}>
                <div style={{height: 45}}>
                    <input style={{height: "100%"}} value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Recherche" className="input-search" type="text"/>
                </div>
                <div style={{width: "20%"}}>
                    <p style={{textAlign: "left"}}>
                        {getFilterString()}
                    </p>
                </div>
                <div style={{width: "20%"}}>
                    <ExportButton type={"sales"} orderIds={sales.map((order) => order.id)} />
                </div>
                <div>
                    <button className="right dheader-button" onClick={handleSearch}>LANCER MA RECHERCHE</button>
                </div>
                <div>
                    <div style={styles.paginationContainer}>
                        <div style={styles.paginationCount}>
                            Page {page}/{Math.ceil(sales.length / 20)} (total: {sales.length})
                        </div>
                        <div style={styles.paginationButtonContainer}>
                            <select style={{"border": "none", "background": "transparent", "outline": "0"}} onChange={(e) => {
                                setPage(Number(e.target.value))
                            }}>
                                {getPages()}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div style={{marginTop: 20}}><img className="loading-logo" src="/favicon.svg" alt="logo"/></div> :
                <div>
                    <DashboardTable header={[
                        "N° de commande",
                        "Quantité",
                        "Prix",
                        "Adresse de livraison",
                        "Date de livraison",
                        "Coordonnées du client",
                        "Code entreprise",
                    ]} data={ordersCell}/>
                </div>
            }
            <div style={{display: "flex", justifyContent: "flex-end", flex: 1, marginBottom: 50}}>
                <div>
                    <div style={styles.paginationContainer}>
                        <div style={styles.paginationCount}>
                            Page {page}/{Math.ceil(sales.length / 20)} (total: {sales.length})
                        </div>
                        <div style={styles.paginationButtonContainer}>
                            <select style={{"border": "none", "background": "transparent", "outline": "0"}} onChange={(e) => {
                                setPage(Number(e.target.value))
                            }}>
                                {getPages()}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    paginationContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginLeft: "5vmax",
        marginRight: "5vmax",
        marginTop: "1vmax",
    },
    paginationButtonContainer: {
        display: "flex",
        width: "70px",
        background: "#efefef",
        borderRadius: "5px",
        height: "30px",
        justifyContent: "space-around",
        alignItems: "center",
        boxShadow: "0px 2px 7px rgba(66,66,66,.51)",
        webkitBoxShadow: "0px 2px 7px rgba(66,66,66,.51)",
        mozBoxShadow: "0px 2px 7px rgba(66,66,66,.51)",

    },
    paginationButton: {
        fontWeight: 800,
        color: "#1b1b1b",
        width: "50%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none"
    },
    paginationCount: {
        display: "flex",
        alignItems: "center",
        marginRight: "10px"
    }
};


export default Sales;
