import gql from "graphql-tag";

const UPLOAD_FILE = gql`
    mutation upload($file: Upload!) {
        uploadSingleFile(file: $file) {
            id
        }
    }
`;

export default UPLOAD_FILE;