import React, { Component } from "react";
import "../styles/container.css"

interface PopupContainerProps {
    component: React.ReactNode
    label: string
    onClick: any
}

export class PopupContainer extends Component<PopupContainerProps> {
    render() {
        return (
            <div className="PopupContainer" id="popup">
                <div className="PopupBorder">
                    <div onClick={this.props.onClick} className="PopupClose">✕</div>
                    <h1>{this.props.label}</h1>
                    {this.props.component}
                </div>
            </div>
        )
    }
}

export default PopupContainer;