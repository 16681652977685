import gql from "graphql-tag";

const REM_PRODUCT = gql`
    mutation deleteProduct($id : ID!) {
        updateProduct(data: {deleted: true}, where: {
            id: $id
        }) {
            id
        }
    }
`;

export default REM_PRODUCT;
