import gql from "graphql-tag";

const REM_RECIPE = gql`
    mutation removeRecipe($id : ID!) {
        deleteRecipe(where: {
            id: $id
        }) {
            id
        }
    }
`;

export default REM_RECIPE;