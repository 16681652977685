import React, {Component} from "react";
import "../../styles/DashboardTable.css"
import {Item, Order} from "../../types/Orders";
import {client} from "../App";
import GET_USER_BY_ORDER from "../../graphql/queries/getUserByOrder";
import moment from 'moment';
import {Moment} from "moment";
// @ts-ignore
import localization from "moment/locale/fr";
import {UPDATE_ORDER_STATUS} from '../../graphql/mutations/UpdateOrder';
import {GET_ORDER} from '../../graphql/queries/getAllOrders';
import {PrettyString} from "../Products/ProductCell";
import CLIENT_ORDER_VALIDATED_MAIL from "../../graphql/mutations/mails/clientOrderValidatedMail";
import CLIENT_ORDER_CANCELED_BY_ADMIN from "../../graphql/mutations/mails/clientOrderCanceledByAdmin";
import CLIENT_ORDER_DELIVERED from "../../graphql/mutations/mails/clientOrderDelivered";
import CANCEL_ORDER from "../../graphql/mutations/cancelOrder";
import {GET_PAYMENT_INTENT} from "../../graphql/queries/getPaymentIntent";
import {PhoneFormat} from "../Misc/PhoneFormat";
import ExportButton from "../Misc/ExportButton";
import {UPDATE_PRODUCT_STOCK} from "../../graphql/mutations/UpdateProduct";
import ARCHIVE_ORDER, { UNARCHIVE_ORDER } from "../../graphql/mutations/ArchiveOrder";

moment.locale('fr', localization);

interface orderProps {
    order: Order
}

interface userInfos {
    address: string
    postalCode: string
    city: string
    name: string
    surname: string
    phone: string
    email: string
}

interface State {
    loading: boolean
    userInfo: userInfos|undefined
    deliveryDate: Moment,
    order: Order
    stripeLink: string
}

class OrderListCell extends Component<orderProps, State> {
    constructor(props:orderProps) {
        super(props);
        this.state = {
            loading: true,
            userInfo: undefined,
            deliveryDate: moment(this.props.order.created_at).format("dddd").toLowerCase() === this.props.order.delivery_location.day.toLowerCase() ?
                moment(this.props.order.created_at).days(this.props.order.delivery_location.day).add(7, 'days')
                : (moment(this.props.order.created_at).days(this.props.order.delivery_location.day).isBefore(moment(this.props.order.created_at)) ?
                    moment(this.props.order.created_at).days(this.props.order.delivery_location.day).add(7, "days")
                    : moment(this.props.order.created_at).days(this.props.order.delivery_location.day)),
            order: this.props.order,
            stripeLink: ""
        };
        this.unarchive = this.unarchive.bind(this);
    }

    componentDidMount(): void {
        client.query({query: GET_USER_BY_ORDER, variables: {id: this.state.order.id}}).then((res) => {
            if (res.data.users[0] && res)
                this.setState({userInfo: res.data.users[0]})
        }).then((err) => {//console.log(err)
            })
        client.query({query: GET_PAYMENT_INTENT, variables: {chargeID: this.state.order.chargeID}}).then((res) => {
            this.setState({stripeLink: res.data.getPaymentIntent})
        })
    }

    unarchive(): void {
        client.mutate({mutation: UNARCHIVE_ORDER, variables: {
            id: this.state.order.id
        }}).then((res) => {
            window.location.reload()
        })
    }

    render() {
        let products = this.state.order.items.map((item:Item) =>
            <div className="items-products" key={item.product.id}>
                <div>{item.product.name}</div>
                <div className="items-products-separator">-</div>
                <div className="items-products-qty">{item.count} × {item.product.unit}</div>
            </div>
        );

        let status = <div className="status-label">Chargement</div>;

        //console.log(this.state.order.status);

        switch (this.state.order.status) {
            case "VALIDATED":
                status = <div className="status-label status-orange">EN ATTENTE DE LIVRAISON</div>;
                break;
            case "NEED_VALIDATION":
                status = <div className="status-label status-yellow">EN ATTENTE DE VALIDATION</div>;
                break;
            case "IN_COMMING":
                status = <div className="status-label status-green">COMMANDE ENVOYÉE</div>;
                break;
            case "CANCELLED":
                status = <div className="status-label status-red">ANNULÉE</div>;
                break;
            case "SHIPPED":
                status = <div className="status-label status-green">COMMANDE ENVOYÉE</div>;
                break;
        }

        return (
            <div className="dash-table-row">
                <div className="dash-table-cell" style={{flexDirection: 'column'}}>
                    <div>
                        <ExportButton type={"order"} orderId={this.state.order.id} orderNb={`N°${this.state.order.nb.toUpperCase()}`}/>
                    </div>
                    {moment(this.state.order.created_at).isAfter(moment().subtract(1, 'day')) &&
                    <div style={{marginTop: "5px"}} className="status-label status-green">NOUVELLE<br/>COMMANDE</div>}
                </div>
                <div className="dash-table-cell-qty" style={{flexDirection: "column-reverse"}}>{products}</div>
                <div className="dash-table-cell dash-table-cell-reduce">{this.state.order.price + this.state.order.shipping_price} €</div>
                <div className="dash-table-cell dash-table-cell-expand">
                    {this.props.order.address + ","}<br/>
                    {this.props.order.postalCode + ", " + PrettyString(this.props.order.city)}
                </div>
                <div className="dash-table-cell dash-table-cell-reduce">{this.state.deliveryDate.format("ddd DD/MM")}</div>
                <div className="dash-table-cell" style={{flexDirection: "column"}}>
                    {this.state.userInfo && (this.state.userInfo.surname + " " + this.state.userInfo.name)}<br/>
                    {this.state.userInfo && (PhoneFormat(this.state.userInfo.phone))}
                </div>
                <div className="dash-table-cell">
                    {this.props.order.companyCode ?
                        this.props.order.companyCode.code + "\n" + this.props.order.companyCode.address + " " + this.props.order.companyCode.city + " " + this.props.order.companyCode.postalCode :
                        "Pas de code entreprise"
                    }
                </div>
                <div style={{flexDirection: "column"}} className="dash-table-cell">{status} <a style={{marginTop: "5px"}} target="_blank" href={"https://dashboard.stripe.com/payments/" + this.state.stripeLink}>Voir dans stripe</a></div>
                <div className="dash-table-cell dash-table-cell-reduce">
                    <button title="Désarchiver" onClick={() => {this.unarchive()}} className="dash-table-button">✘</button>
                </div>
            </div>
        )
    }
}

let mitigeStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
};

export default OrderListCell;
