import React, {Component} from "react";
import LOGIN from "../../graphql/mutations/Login";
import {AuthContext, client} from "../App";

interface State {
    email: string,
    password: string,
    valid: boolean
}

class LoginComponent extends Component<any, State> {

    constructor(props:any) {
        super(props);
        this.state = {
            email: "",
            password: "",
            valid: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e: React.BaseSyntheticEvent) {
        if (e.target.name === "email")
            this.setState({email: e.target.value});
        if (e.target.name === "password")
            this.setState({password: e.target.value});
    }

    handleSubmit(e: React.BaseSyntheticEvent) {
        e.preventDefault();
        client.mutate({mutation: LOGIN, variables: {email: this.state.email, password: this.state.password}}).then((res) => {
            //console.log(res);
            if (!res.errors && res.data.login.User.type === "ADMIN") {
                window.localStorage.setItem("token", res.data.login.token);
                window.localStorage.setItem("expireDate", res.data.login.expireDate);
                window.location.href = "/";
            } else
                alert("erreur")
        }).catch((err) => {alert("erreur")})
    }

    render() {
        return (
            <AuthContext.Consumer>
                {context => (
                    <>
                        <div className="login-page">
                            <form className="login-container">
                                <img src={"/img/logo.png"} alt={"logo"} className="login-logo"/>
                                <h1>Bienvenue</h1>
                                <input name="email" onChange={this.handleChange} value={this.state.email} placeholder="identifiant" className="login-input" type="text"/>
                                <input name="password" onChange={this.handleChange} value={this.state.password} placeholder="mot de passe" className="login-input" type="password"/>
                                <button onClick={this.handleSubmit} className="login-button" type="submit">Connexion</button>
                            </form>
                        </div>
                    </>
                )}
            </AuthContext.Consumer>
        )
    }
}

export default LoginComponent;
