import gql from "graphql-tag";

const ADD_COMPANY_CODE = gql`
    mutation createCompanyCode($code: String!, $address: String!, $city: String!, $postalCode: String!) {
        createCompanyCode(data:{
            code: $code,
            address: $address,
            city: $city,
            postalCode: $postalCode
        }) {
            id
        }
    }
`;

export default ADD_COMPANY_CODE;
