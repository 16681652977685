import React, {Component} from 'react';
import {Companycode} from '../../types/Companycode';
import {client} from '../App';
import ALL_COMPANY_CODE from '../../graphql/queries/getAllCompanyCode';
import {Loading} from '../Misc/Loading';
import CompanyCodeListCell from './CompanyCodeListCell';
import TableContainer from '../../container/TableContainer';

interface State {
    loading: boolean
    codes: Array<Companycode>
}

class CompanyCodeList extends Component<object, State> {
    constructor(props:object) {
        super(props);
        this.state = {
            loading: true,
            codes: []
        }
    }

    componentDidMount(): void {
        client.query({query: ALL_COMPANY_CODE}).then((data:any) => {
            this.setState({loading: false, codes: data.data.companyCodes})
        }).catch((err) => {
            //console.log(err);
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (Loading);
        let codesCell = this.state.codes.map((code:Companycode) =>
            <CompanyCodeListCell code={code} key={code.id} />
        );
        return (
            TableContainer(
                "Liste des codes entreprises:",
                "",
                [
                    "",
                    "Code",
                    "Adresse",
                    "Ville",
                    "Code Postal"
                ],
                codesCell,
                null,
                "",
                "",
                "",
                "",
                [],
                true
            )
        );
    }
}

export default CompanyCodeList;
