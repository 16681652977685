import React, {useEffect, useState} from "react";
import {client} from "../App";
import NEW_PARAMETER, {DEL_PARAMETER} from "../../graphql/mutations/NewParameter";
import {useQuery} from "@apollo/react-hooks";
import GET_APP_MESSAGE, {GET_MESSAGE_PARAMETER} from "../../graphql/queries/getAppMessage";
import {Loading} from "../Misc/Loading";
import EDIT_MESSAGE from "../../graphql/mutations/EditMessage";

interface getAppMessageResponse {
    getAppMessage: string;
}

interface GetParameterResponse {
  parameters: Parameter[];
}

interface Parameter {
  identifier: string;
  data: string;
  id: string;
  __typename: string;
}

const MessageApp = () => {

    const [message, setMessage] = useState<string | undefined>("");
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const {loading, error, data} = useQuery<GetParameterResponse>(GET_MESSAGE_PARAMETER)
    const [mode, setMode] = useState("default");
    const [editable, setEditable] = useState(false);
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (data && data.parameters[0]) {
            const messageData = JSON.parse(data.parameters[0].data)
            setMessage(messageData.message)
            setEndDate(messageData.endDate)
            setStartDate(messageData.startDate)
        }
    }, [data])

    if (loading) return Loading;


    const handleSubmit = () => {
        const newData = {
            message,
            startDate,
            endDate
        }
        client.mutate({
            mutation: EDIT_MESSAGE, variables: {
                data: JSON.stringify(newData),
            }
        }).then((res) => {
            console.log(JSON.stringify(res))
            setDone(true);
            setTimeout(() => {setDone(false)}, 3000)
            setMode("default")
        })
    }

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: 20}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "20%"}}>
                <h4 style={{width: '100%'}} className="input-recipe-label">Message de l'app:</h4>
                <textarea rows={4} style={{marginTop: 5, width: '100%'}} placeholder={message} className="input-recipe" value={message} onChange={(e) => setMessage(e.target.value)}/>
                <h4 style={{width: '100%'}} className="input-recipe-label">Date de début:</h4>
                <input style={{marginTop: 5, width: '100%'}} className="input-recipe" type={"date"} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                <h4 style={{width: '100%'}} className="input-recipe-label">Date de fin:</h4>
                <input style={{marginTop: 5, width: '100%', marginBottom: 10}} className="input-recipe" type={"date"} value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                <button style={{margin: 0, width: '100%'}} className={"button-recipe dheader-button"} onClick={handleSubmit}>Changer</button>
            {done &&
            <div style={{marginTop: 20}}>
                Message enregistré ✔
            </div>
            }
            </div>
        </div>
    )
}

export default MessageApp;
