import React from "react";

export const PhoneFormat = (phone: string) => {
    const splited = phone.split("+33")
    if (phone.includes("+33")) {
        return <a href={`tel:${phone}`}>
            {`+33 ${splited[1][0]} ${splited[1][1]}${splited[1][2]} ${splited[1][3]}${splited[1][4]} ${splited[1][5]}${splited[1][6]} ${splited[1][7]}${splited[1][8]}`}
        </a>;
    } else {
        console.log(phone)
        return <a href={`tel:${phone}`}>
            {`+33 ${phone[1]} ${phone[2]}${phone[3]} ${phone[4]}${phone[5]} ${phone[6]}${phone[7]} ${phone[8]}${phone[9]}`}
        </a>;
    }
}
