import React, {Component } from "react";
import { RecipesCell } from "../../types/Recipes";
import "../../styles/DashboardTable.css";
import {GET_FILE} from "../../graphql/queries/getFile";
import {client} from "../App";
import {UPDATE_RECIPE_VISIBLE} from "../../graphql/mutations/UpdateRecipe";
import LoadingCell from "../Misc/LoadingCell";
import manageError from "../Misc/Error";
import REM_RECIPE from "../../graphql/mutations/RemoveRecipe";
import GET_RECIPE from "../../graphql/queries/getSingleRecipe";

interface cellProps {
    recipe: RecipesCell
    key: number
}

interface cellState {
    visible: boolean
    loading: boolean
    photo: any
}

export class RecipesListCell extends Component<cellProps, cellState> {
    constructor(props:cellProps) {
        super(props);
        this.state = {
            visible: props.recipe.visible,
            loading: true,
            photo: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount(): void {
        client.query({query: GET_FILE, variables:{id: this.props.recipe.photo.id}}).then((data:any) => {
            this.setState({photo: data.data.getFile, loading: false})
        }).catch((error) => {
            manageError(error);
        })
    }

    handleChange = (e:any) => {
        client.mutate({
            mutation: UPDATE_RECIPE_VISIBLE,
            variables: {visible: !this.state.visible, id: this.props.recipe.id},
            refetchQueries: [{
                query: GET_RECIPE,
                variables: {id: this.props.recipe.id}
            }]}).then(result => {
                this.setState({visible: !this.state.visible});
        }).catch(error => {manageError(error)});
    };

    handleRemove = (e:any) => {
        client.mutate({
            mutation: REM_RECIPE,
            variables: {id: this.props.recipe.id},
            refetchQueries: [{
                query: GET_RECIPE,
                variables: {id: this.props.recipe.id}
            }]}).then(res => {
                window.location.reload();
        })
    };

    render() {
        if (this.state.loading)
            return(
                LoadingCell(["", "shimmer-text", "shimmer-img", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-switch"])
            );
        return(
        <div className="dash-table-row">
            <div className="dash-table-empty"><button onClick={this.handleRemove} className="dash-table-button">X</button></div>
            <div className="dash-table-cell">{this.props.recipe.title}</div>
            <div className="dash-table-cell table-cell-img">
                <img alt={this.state.photo.File.filename} src={"data:" + this.state.photo.File.mimeType + ";base64," + this.state.photo.data} />
            </div>
            <div className="dash-table-cell">{this.props.recipe.time} minute{this.props.recipe.time > 1 && "s"}</div>
            <div className="dash-table-cell">{this.props.recipe.number_of_people} personne{this.props.recipe.number_of_people > 1 && "s"}</div>
            <div className="dash-table-cell">{this.props.recipe.author}</div>
            <div className="dash-table-cell dash-table-multiple">
                <div className="onoffswitch">
                    <input checked={this.state.visible} onChange={e => this.handleChange(e)} type="checkbox" className="onoffswitch-checkbox" id={this.props.recipe.id} />
                    <label className="onoffswitch-label" htmlFor={this.props.recipe.id} />
                </div>
            </div>
        </div>
        )
    }

}

export default RecipesListCell
