import gql from 'graphql-tag';

export const UPDATE_DELLOC_DISABLED = gql`
    mutation updateDellocDisabled($disabled: Boolean!, $id: ID!) {
        updateDeliveryLocation(
            data: {
                disabled: $disabled
            }, where: {
                id: $id
            }
        ) {
            id
        }
    }
`;
