import React from "react";
import "../../styles/Manage.css"
import AddVarietyForm from "../Variety/NewVariety";
import VarietyList from "../Variety/VarietyList";
import {AddCompanyCodeForm} from "../CompanyCode/NewCompanyCode";
import CompanyCodeList from '../CompanyCode/CompanyCodeList';
import AddDeliveryLocationForm from '../DeliveryLocation/NewDeliveryLocation';
import DeliveryCodeList from '../DeliveryLocation/DeliveryCodeList';
import MessageApp from "./MessageApp";

class Manage extends React.Component {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <MessageApp/>
                <div className="manage">
                    <div style={{alignItems: "flex-end"}} className="manage-line">
                        <div className="manage-flex">
                            <AddVarietyForm/>
                        </div>
                        <div className="manage-flex">
                            <AddCompanyCodeForm/>
                        </div>
                        <div className="manage-flex">
                            <AddDeliveryLocationForm/>
                        </div>
                    </div>
                    <div className="manage-line">
                        <div className="manage-flex">
                            <VarietyList/>
                        </div>
                        <div className="manage-flex">
                            <CompanyCodeList/>
                        </div>
                        <div className="manage-flex">
                            <DeliveryCodeList/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Manage;
