import gql from "graphql-tag";

const GET_APP_MESSAGE = gql`
    query {
        getAppMessage
    }
`;

export const GET_MESSAGE_PARAMETER = gql`
    query {
        parameters(where: {identifier: "APP_MESSAGE"}) {
            identifier
            data
            id
        }
    }
`;

export default GET_APP_MESSAGE;
