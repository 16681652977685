import gql from 'graphql-tag';

export const UPDATE_ORDER_STATUS = gql`
    mutation updateOrderStatus($status: OrderStatus!, $id: ID!) {
        updateOrder(
            data: {
                status: $status
            }, where: {
                id: $id
            }
        ) {
            id
        }
    }
`;
