import gql from "graphql-tag";

export const GET_USERS_BY_ID = gql`
    query users($id: [ID!]) {
        users(where: {orders_some: {id_in: $id}}) {
            id
            name
            surname
            orders {
                id
            }
        }
    }
`;

const GET_USERS = gql`
    query users($query: String, $skip: Int, $first: Int, $orderBy: UserOrderByInput) {
        users(skip: $skip, first: $first, orderBy: $orderBy, where: {
            OR: [
                {email_contains: $query}, {name_contains: $query}, {surname_contains: $query},
                {address_starts_with: $query}, {city_starts_with: $query}, {phone_starts_with: $query}, {postalCode_starts_with: $query},
                {company_code: {code_contains: $query}}, {company_code: {address_contains: $query}}, {company_code: {postalCode_contains: $query}},
                {company_code: {city_contains: $query}}
            ], AND: [
                {type: USER}
            ]
        }) {
            surname
            name
            email
            phone
            address
            city
            postalCode
            orders {id}
            id
            company_code {
                id
                code
                address
                postalCode
                city
            }
        }
    }
`;

export default GET_USERS;
