import React from 'react'
import TableContainer from "../../container/TableContainer";
import UsersListCell from "./UsersListCell";
import GET_USERS from "../../graphql/queries/getAllUsers";
import { User } from "../../types/Users";
import {Loading} from "../Misc/Loading";
import Pagination from '../../types/Pagination';
import {client} from '../App';
import ExportButton from "../Misc/ExportButton";

interface State {
    loading: boolean
    query: string
    page: Pagination
    data: any
    orderBy: string
}

class UsersList extends React.Component<any, State>  {
    constructor(props: any) {
        super(props);
        let first = 2;
        let skip = 0;
        let next = "";
        let prev = "";
        if (Number(this.props.match.params.skip))
            skip = Number(this.props.match.params.skip);
        if (Number(this.props.match.params.first)) {
            first = Number(this.props.match.params.first);
            next = (skip + first).toString()
        }
        this.state = {
            query: "",
            loading: true,
            page: {totalCount: -1, next:"", prev:"", location:"utilisateurs", first:first, skip:skip},
            data: null,
            orderBy: "name_ASC"
        };
        this.setQuery = this.setQuery.bind(this);
        this.updateMe = this.updateMe.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
    }

    updateMe(): void {
        client.query({query: GET_USERS, variables: {
            query: this.state.query.toLowerCase(),
            orderBy: this.state.orderBy
        }}).then((res) => {
            let newPage = this.state.page;
            newPage.totalCount = res.data.users.length;
            this.setState({page: newPage});
            client.query({query: GET_USERS, variables: {
                query:this.state.query.toLowerCase(), skip: this.state.page.skip, first: this.state.page.first,
                orderBy: this.state.orderBy
            }}).then((res) => {
                this.setState({data: res.data, loading: false})
            })
        });
    }

    componentDidMount(): void {
        this.updateMe();
    }

    setQuery(query:string) {
        let newPage = this.state.page;
        newPage.skip = 0;
        this.setState({query, page:newPage});
        this.updateMe();
    }

    toggleSort(field: string) {
        if (field === "name") {
            if (this.state.orderBy === "name_ASC") {
                this.setState({orderBy: "name_DESC"})
            } else {
                this.setState({orderBy: "name_ASC"})
            }
        } else if (field === "surname") {
            if (this.state.orderBy === "surname_ASC") {
                this.setState({orderBy: "surname_DESC"})
            } else {
                this.setState({orderBy: "surname_ASC"})
            }
        }
        this.updateMe();
    }

    getSortState(field: string) {
        if (field === "name") {
            if (this.state.orderBy === "name_ASC") {
                return (
                    <div onClick={() => this.toggleSort(field)}>
                        Prénom {this.state.orderBy.indexOf("surname") === -1 && "▾"}
                    </div>
                )
            } else {
                return (
                    <div onClick={() => this.toggleSort(field)}>
                        Prénom {this.state.orderBy.indexOf("surname") === -1 && "▴"}
                    </div>
                )
            }
        } else if (field === "surname") {
            if (this.state.orderBy === "surname_ASC") {
                return (
                    <div onClick={() => this.toggleSort(field)}>
                        Nom {this.state.orderBy.indexOf("surname") !== -1 && "▾"}
                    </div>
                )
            } else {
                return (
                    <div onClick={() => this.toggleSort(field)}>
                        Nom {this.state.orderBy.indexOf("surname") !== -1 && "▴"}
                    </div>
                )
            }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.loading)
            return (Loading);
        let usersCell = this.state.data.users.map((user:User, key:number) =>
            <UsersListCell user={user}/>
        );
        return (
            TableContainer(
                "Liste des utilisateurs:",
                "",
                [
                    "",
                    this.getSortState("name"),
                    this.getSortState("surname"),
                    "Adresse mail",
                    "Téléphone",
                    "Adresse",
                    "Code entreprise",
                    "Nombre de commandes passées",
                ],
                usersCell,
                null,
                "",
                undefined,
                this.setQuery,
                this.state.query,
                undefined,
                undefined,
                this.state.page,
                <ExportButton type={"user"}/>
            )
        )
    }
}

/*
export function UsersList() {
    const [query, setQuery] = React.useState("");
    let {loading, error, data} = useQuery(GET_USERS, {variables: {query}});
    if (loading)
        return (Loading);
    if (error)
        return (manageError());
    let usersCell = data.users.map((user:User, key:number) =>
        <UsersListCell user={user}/>
    );
    return (
        TableContainer(
            "Liste des utilisateurs:",
            "",
            [
                "",
                "Prénom",
                "Nom",
                "Adresse mail",
                "Téléphone",
                "Adresse",
                "Nombre de commandes passées",
            ],
            usersCell,
            null,
            "",
            undefined,
            setQuery,
            query
        )
    )
}*/

export default UsersList;
