import React, {Component} from "react";
import "../../styles/DashboardTable.css"
import {Item, Order} from "../../types/Orders";
import {client} from "../App";
import GET_USER_BY_ORDER from "../../graphql/queries/getUserByOrder";
import moment from 'moment';
import {Moment} from "moment";
// @ts-ignore
import localization from "moment/locale/fr";
import {UPDATE_ORDER_STATUS} from '../../graphql/mutations/UpdateOrder';
import {GET_ORDER} from '../../graphql/queries/getAllOrders';
import {PrettyString} from "../Products/ProductCell";
import CLIENT_ORDER_VALIDATED_MAIL from "../../graphql/mutations/mails/clientOrderValidatedMail";
import CLIENT_ORDER_CANCELED_BY_ADMIN from "../../graphql/mutations/mails/clientOrderCanceledByAdmin";
import CLIENT_ORDER_DELIVERED from "../../graphql/mutations/mails/clientOrderDelivered";
import CANCEL_ORDER from "../../graphql/mutations/cancelOrder";
import {GET_PAYMENT_INTENT} from "../../graphql/queries/getPaymentIntent";
import {PhoneFormat} from "../Misc/PhoneFormat";
import ExportButton from "../Misc/ExportButton";
import {UPDATE_PRODUCT_STOCK} from "../../graphql/mutations/UpdateProduct";
import ARCHIVE_ORDER from "../../graphql/mutations/ArchiveOrder";

moment.locale('fr', localization);

interface orderProps {
    order: Order
    minimize?: boolean
}

interface userInfos {
    address: string
    postalCode: string
    city: string
    name: string
    surname: string
    phone: string
    email: string
}

interface State {
    loading: boolean
    userInfo: userInfos|undefined
    deliveryDate: Moment,
    order: Order
    stripeLink: string
}

class OrderListCell extends Component<orderProps, State> {
    constructor(props:orderProps) {
        super(props);
        this.state = {
            loading: true,
            userInfo: undefined,
            deliveryDate: moment(this.props.order.created_at).format("dddd").toLowerCase() === this.props.order.delivery_location.day.toLowerCase() ?
                moment(this.props.order.created_at).days(this.props.order.delivery_location.day).add(7, 'days')
                : (moment(this.props.order.created_at).days(this.props.order.delivery_location.day).isBefore(moment(this.props.order.created_at)) ?
                    moment(this.props.order.created_at).days(this.props.order.delivery_location.day).add(7, "days")
                    : moment(this.props.order.created_at).days(this.props.order.delivery_location.day)),
            order: this.props.order,
            stripeLink: ""
        };
        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount(): void {
        client.query({query: GET_USER_BY_ORDER, variables: {id: this.state.order.id}}).then((res) => {
            if (res.data.users[0] && res)
                this.setState({userInfo: res.data.users[0]})
        }).then((err) => {//console.log(err)
            })
        client.query({query: GET_PAYMENT_INTENT, variables: {chargeID: this.state.order.chargeID}}).then((res) => {
            this.setState({stripeLink: res.data.getPaymentIntent})
        })
    }

    changeStatus(status: string): void {
        //this.state.order.items[0].product.id
        if (status === "ARCHIVE") {
            client.mutate({
                mutation: ARCHIVE_ORDER, variables: {
                    id: this.state.order.id, archived: true
                }, refetchQueries: [{query: GET_ORDER, variables: {id: this.state.order.id}}]
            }).then((res) => {
                //window.location.reload()
            })
        }
        else client.mutate({mutation: UPDATE_ORDER_STATUS, variables: {status: status, id: this.state.order.id},
        refetchQueries: [{query: GET_ORDER, variables: {id: this.state.order.id}}]})
            .then(() => {
                let order = this.state.order;
                order.status = status;
                if (status === "VALIDATED") {
                    this.state.userInfo && client.mutate({
                        mutation: CLIENT_ORDER_VALIDATED_MAIL, variables: {
                            name: this.state.userInfo.name,
                            email: this.state.userInfo.email,
                            day: this.props.order.delivery_location.day,
                            date: this.state.deliveryDate.format("ddd DD/MM"),
                            hour_min: this.props.order.delivery_location.hour_start,
                            hour_max: this.props.order.delivery_location.hour_end
                        }
                    })
                    this.state.order.items.forEach((itm) => {
                        client.mutate({
                            mutation: UPDATE_PRODUCT_STOCK, variables: {
                                id: itm.product.id,
                                stock: itm.product.stock - itm.count
                            }
                        })
                    })
                } if (status === "CANCELLED") {
                    this.state.userInfo && client.mutate({
                        mutation: CLIENT_ORDER_CANCELED_BY_ADMIN, variables: {
                            name: this.state.userInfo.name,
                            email: this.state.userInfo.email
                        }
                    }).then((res) => {
                        client.mutate({
                            mutation: CANCEL_ORDER, variables: {
                                id: this.props.order.id
                            }
                        })
                    })
                    this.state.order.items.forEach((itm) => {
                        client.mutate({
                            mutation: UPDATE_PRODUCT_STOCK, variables: {
                                id: itm.product.id,
                                stock: itm.product.stock - itm.count
                            }
                        })
                    })
                    client.mutate({mutation: ARCHIVE_ORDER, variables: {
                        id: this.state.order.id, archived: true
                        }
                    }).then((res) => {
                        //window.location.reload()
                    })
                } if (status === "SHIPPED") {
                    this.state.userInfo && client.mutate({
                        mutation: CLIENT_ORDER_DELIVERED, variables: {
                            email: this.state.userInfo.email
                        }
                    })
                    client.mutate({mutation: ARCHIVE_ORDER, variables: {
                        id: this.state.order.id, archived: true
                        }
                    }).then((res) => {
                        //window.location.reload()
                    })
                }
                this.setState({order: order});
            });
    }

    render() {
        let products = this.state.order.items.map((item:Item) =>
            <div className="items-products" key={item.product.id}>
                <div>{item.product.name}</div>
                <div className="items-products-separator">-</div>
                <div className="items-products-qty">{item.count} × {item.product.unit}</div>
            </div>
        );

        let status = <div className="status-label">Chargement</div>;

        //console.log(this.state.order.status);

        switch (this.state.order.status) {
            case "VALIDATED":
                status = <div className="status-label status-orange">EN ATTENTE DE LIVRAISON</div>;
                break;
            case "NEED_VALIDATION":
                status = <div className="status-label status-yellow">EN ATTENTE DE VALIDATION</div>;
                break;
            case "IN_COMMING":
                status = <div className="status-label status-green">COMMANDE ENVOYÉE</div>;
                break;
            case "CANCELLED":
                status = <div className="status-label status-red">ANNULÉE</div>;
                break;
            case "SHIPPED":
                status = <div className="status-label status-green">COMMANDE ENVOYÉE</div>;
                break;
        }

        return (
            <div className="dash-table-row">
                <div className="dash-table-cell" style={{flexDirection: 'column'}}>
                    <div>
                        <ExportButton type={"order"} orderId={this.state.order.id} orderNb={`N°${this.state.order.nb.toUpperCase()}`}/>
                    </div>
                    {moment(this.state.order.created_at).isAfter(moment().subtract(1, 'day')) &&
                    <div style={{marginTop: "5px"}} className="status-label status-green">NOUVELLE<br/>COMMANDE</div>}
                </div>
                <div className="dash-table-cell-qty" style={{flexDirection: "column-reverse"}}>{products}</div>
                <div className="dash-table-cell dash-table-cell-reduce">{this.state.order.price + this.state.order.shipping_price} €</div>
                <div className="dash-table-cell dash-table-cell-expand">
                    {this.props.order.address + ","}<br/>
                    {this.props.order.postalCode + ", " + PrettyString(this.props.order.city)}
                </div>
                <div className="dash-table-cell dash-table-cell-reduce">{this.state.deliveryDate.format(this.props.minimize ? "ddd DD/MM/YY" : "ddd DD/MM")}</div>
                <div className="dash-table-cell" style={{flexDirection: "column"}}>
                    {this.state.userInfo && (this.state.userInfo.surname + " " + this.state.userInfo.name)}<br/>
                    {this.state.userInfo && (PhoneFormat(this.state.userInfo.phone))}
                </div>
                <div className="dash-table-cell">
                    {this.props.order.companyCode ?
                        this.props.order.companyCode.code + "\n" + this.props.order.companyCode.address + " " + this.props.order.companyCode.city + " " + this.props.order.companyCode.postalCode :
                        "Pas de code entreprise"
                    }
                </div>
                {!this.props.minimize &&
                <>
                <div style={{flexDirection: "column"}} className="dash-table-cell">{status} <a style={{marginTop: "5px"}} target="_blank" href={"https://dashboard.stripe.com/payments/" + this.state.stripeLink}>Voir dans stripe</a></div>
                <div className="dash-table-cell dash-table-cell-reduce">
                    {this.state.order.status === "NEED_VALIDATION" ?
                        <div style={mitigeStyle}>
                            <button title="Valider la commande" onClick={() => {this.changeStatus("VALIDATED")}} className="dash-table-button dash-table-validate">✔</button>
                            <button title="Refuser la commande" onClick={() => {this.changeStatus("CANCELLED")}} className="dash-table-button">✘</button>
                        </div>
                    : this.state.order.status === "CANCELLED" ?
                        <div>
                            <p>Commande annulée</p>
                            <button title="Archiver la commande" onClick={() => {this.changeStatus("ARCHIVE")}} className="dash-table-button">✘</button>
                        </div>
                    : this.state.order.status === "VALIDATED" ?
                        <button title="Marquer comme envoyée" onClick={() => {this.changeStatus("SHIPPED")}} className="dash-table-button dash-table-validate">✔</button>
                    : (this.state.order.status === "SHIPPED" || this.state.order.status === "IN_COMMING") &&
                        <div>
                            <p>Commande envoyée</p>
                            <button title="Archiver la commande" onClick={() => {this.changeStatus("ARCHIVE")}} className="dash-table-button">✘</button>
                        </div>
                    }
                </div>
                </>
                }
            </div>
        )
    }
}

let mitigeStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
};

export default OrderListCell;
