import gql from "graphql-tag";

const NEW_VARIETY = gql`
    mutation createVariety($name: String!, $main_color: String!, $photo: ID!, $type: ProductType!) {
        createVariety(data: {
            name: $name,
            type: $type,
            main_color: $main_color,
            photo: {connect: {id: $photo}},
        }) {
            id
        }
    }
`;

export default NEW_VARIETY;
