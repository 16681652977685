import gql from "graphql-tag";

export const UPDATE_PRODUCT_VISIBLE = gql`
    mutation updateProductVisible($visible: Boolean, $id: ID) {
        updateProduct(
            data: {
                visible: $visible
            }, where: {
                id:$id
            }
        ) {
            id
        }
    }
`;

export const UPDATE_PRODUCT_PROMOTION = gql`
    mutation updateProductVisible($promotion: Float, $id: ID) {
        updateProduct(
            data: {
                promotion: $promotion
            }, where: {
                id:$id
            }
        ) {
            id
        }
    }
`;

export const UPDATE_PRODUCT_STOCK = gql`
    mutation updateProductVisible($stock: Int, $id: ID) {
        updateProduct(
            data: {
                stock: $stock
            }, where: {
                id:$id
            }
        ) {
            id
        }
    }
`;

const UPDATE_PRODUCT = gql`
    mutation updateProduct($type: ProductType, $variety: ID, $name: String, $photo: ID, $price: Float, $promotion: Float,
        $unit: String, $origin: String, $description: String, $visible: Boolean, $recipe: [RecipeWhereUniqueInput!],
        $where: ID!) {
        updateProduct(
            data: {
                type: $type,
                variety: {connect: {id: $variety}},
                name: $name,
                photo: {connect : {id: $photo}},
                price: $price,
                promotion: $promotion,
                unit: $unit,
                origin: $origin,
                description: $description,
                visible: $visible,
                recipe: {set: $recipe}
            }, where: {id:$where}
        ) {
            id
        }
    }
`;

export const UPDATE_PRODUCT_NOFILE = gql`
    mutation updateProduct($type: ProductType, $variety: ID, $name: String, $price: Float, $promotion: Float,
        $unit: String, $origin: String, $description: String, $visible: Boolean, $recipe: [RecipeWhereUniqueInput!],
        $where: ID!) {
        updateProduct(
            data: {
                type: $type,
                variety: {connect: {id: $variety}},
                name: $name,
                price: $price,
                promotion: $promotion,
                unit: $unit,
                origin: $origin,
                description: $description,
                visible: $visible,
                recipe: {set: $recipe}
            }, where: {id:$where}
        ) {
            id
        }
    }
`;

export default UPDATE_PRODUCT;
