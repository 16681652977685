import gql from "graphql-tag";

const EDIT_MESSAGE = gql`
    mutation editMessage($data: String!) {
        updateParameter(where: {identifier: "APP_MESSAGE"}, data: {data: $data}) {
            identifier
        }
    }
`;

export default EDIT_MESSAGE;
