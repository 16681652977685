import gql from "graphql-tag";

const NEW_PRODUCT = gql`
    mutation createProduct(
        $type: ProductType!,
        $variety: ID!,
        $name: String!,
        $photo: ID!,
        $price: Float!,
        $promotion: Float,
        $unit: String!,
        $origin: String!,
        $description: String!,
        $visible: Boolean!,
        $recipe: [RecipeWhereUniqueInput!]
    ) {
       createProduct( data: {
           type: $type,
           variety: {connect: {id: $variety}},
           name: $name,
           photo: {connect : {id: $photo}},
           price: $price,
           promotion: $promotion,
           unit: $unit,
           origin: $origin,
           description: $description,
           visible: $visible,
           recipe: {connect: $recipe}
       }) {
           id
       }
    }
`;

export default NEW_PRODUCT;