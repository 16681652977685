import gql from 'graphql-tag';

const ADD_DELIVERY_LOCATION = gql`
    mutation createDeliveyLocation($locations: String!, $hour_start: String!, $hour_end: String!, $day: String!) {
        createDeliveryLocation(data:{
            locations: $locations,
            hour_start: $hour_start,
            hour_end: $hour_end,
            day: $day
            disabled: false
        }) {
            id
        }
    }
`;

export default ADD_DELIVERY_LOCATION;
