import gql from "graphql-tag";

export const GET_RECIPES_FORM = gql`
    {
        recipes {
            id
            title
        }
    }
`;

const GET_RECIPES = gql`
    query recipes($query: String, $skip: Int, $first: Int) {
        recipes(skip: $skip, first: $first, orderBy: update_at_DESC, where: {
            OR: [
                {title_contains: $query},
                {author_contains: $query}
            ]
        }) {
            id
            title
            photo {
                id
            }
            time
            number_of_people
            author
            visible
        }
    }
`;

export default GET_RECIPES;
