import React from "react";
import "../../styles/DashboardTable.css"
import {client} from "../App";
import {UPDATE_PRODUCT_PROMOTION} from "../../graphql/mutations/UpdateProduct";

interface Props {
    id: string
    promotion: number
    price: number
}

interface State {
    edit: boolean
    promotion: number
    formPromotion: number
    valid: boolean
}

export class ProductPromotion extends React.Component<Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = {
            edit: false,
            promotion: props.promotion,
            formPromotion: 0,
            valid: false
        };
        this.addPromotion = this.addPromotion.bind(this);
        this.cancelPromotion = this.cancelPromotion.bind(this);
        this.removePromotion = this.removePromotion.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setPromotion = this.setPromotion.bind(this);
    }

    addPromotion(e:any) {
        this.setState({edit: true});
    }

    cancelPromotion(e:any) {
        this.setState({edit: false});
    }

    removePromotion(e:any) {
        client.mutate({mutation: UPDATE_PRODUCT_PROMOTION, variables:{promotion: 0, id: this.props.id}}).then(() => {
            this.setState({promotion:0});
        })
    }

    handleChange(e:any) {
        if (parseFloat(e.target.value) < this.props.price && parseFloat(e.target.value))
            this.setState({formPromotion: parseFloat(e.target.value), valid: true});
        else
            this.setState({valid: false});
    }

    setPromotion(e:any) {
        if (this.state.formPromotion) {
            client.mutate({mutation: UPDATE_PRODUCT_PROMOTION, variables:{promotion: this.state.formPromotion, id: this.props.id}}).then(() => {
                this.setState({promotion: this.state.formPromotion, formPromotion: 0, edit: false})
            }).catch(error => {//console.log(error)
                })
        }
    }

    render() {
        if (this.state.promotion) {
            return (
                <div className="promotion-display">
                    <div style={{fontSize: "small"}}>{this.state.promotion}€</div>
                    <input onClick={this.removePromotion} style={{backgroundColor: "#FF635C"}} className="promotion-button-input" type="submit" value="✘"/>
                </div>
            )
        } else {
            return (
                <>
                {this.state.edit ?
                    (<><div className="promotion-input input-money">
                        <input step="0.1" max={this.props.price - 1} min={0.1} type="number" onChange={e => {this.handleChange(e)}} value={this.state.formPromotion} className="input-recipe"/>
                    </div>
                    <div className="promotion-button">
                        <input disabled={!this.state.valid} onClick={this.setPromotion} className={"promotion-button-input "+(!this.state.valid&&"dheader-button-disabled")} type="submit" value="✔"/>
                        <input className="promotion-button-input" style={{backgroundColor: "#FF635C"}} onClick={this.cancelPromotion} type="submit" value="✘"/>
                    </div></>):
                    <div onClick={this.addPromotion} className="promotion-label">Ajouter une promotion</div>
                }
                </>
            )
        }
    }
}

export default ProductPromotion;
