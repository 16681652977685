import gql from "graphql-tag";

const GET_ORDER_DETAILS = gql`
    query getOrderDetails($orderId: ID!) {
        getOrderDetails(orderID: $orderId) {
            data
            File {
                filename
                mimeType
                encoding
            }
        }
    }
`;

export default GET_ORDER_DETAILS;

