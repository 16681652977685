import gql from 'graphql-tag';

const REM_COMPANY_CODE = gql`
    mutation RemoveCompanyCode($id: ID!) {
        deleteCompanyCode(where: {id: $id}) {
            id
        }
    }
`;

export default REM_COMPANY_CODE;
