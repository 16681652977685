import gql from "graphql-tag";

const GET_USER_LIST = gql`
    query getUserLsit {
        getUserList {
            data
            File {
                filename
                mimeType
                encoding
            }
        }
    }
`;

export default GET_USER_LIST;
