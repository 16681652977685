import gql from "graphql-tag";

const GET_USER = gql`
    query getSingleUser($id: ID!) {
        user(where: {id: $id}) {
            surname
            name
            email
            phone
            address
            orders {id}
            id
        }
    }
`;

export default GET_USER;