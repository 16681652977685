import React, {useEffect, useState} from 'react';
import DashboardHeaderList from "../components/Dashboard/DashboardHeaderList";
import DashboardTable from "../components/Dashboard/DashboardTable";
import "../styles/DashboardTable.css"
import Pagination from '../types/Pagination';

export function TableContainer(label:string, button:string, tableHeader:Array<string|React.ReactNode>, data:any, popupComponent: any, popupLabel: string, redirect?:string, search?:any, query?: string, headerLink?: Array<string>, searchBar?: boolean, pagination?: Pagination, rightComponent?: any) {
    console.log(pagination);
    let sorting = window.location.href.split("/").length === 7 ? ("/" + window.location.href.split("/")[4]) : "";
    const allPages = [];
    if (pagination) {
        for (let i = 0; i < Math.ceil(pagination.totalCount / pagination.first); i++) {
            allPages.push(<option selected={Math.round( pagination.skip / pagination.first) === i} value={i}>Page {i + 1}</option>)
        }
    }
    return (
        <div className="dash-table-container" style={{marginBottom: 30}}>
            <DashboardHeaderList label={label} rightComponent={rightComponent} button={button} popupComponent={popupComponent} popupLabel={popupLabel} search={search} query={query} redirect={redirect} searchBar={searchBar} />
            {pagination &&
                <div style={styles.paginationContainer}>
                    <div style={styles.paginationCount}>
                        Page {Math.round(pagination.skip / pagination.first)+1}/{Math.ceil(pagination.totalCount / pagination.first)} (total: {pagination.totalCount})
                    </div>
                    <div style={styles.paginationButtonContainer}>
                        {/*<button disabled={pagination.skip <= 0}
                                onClick={() => window.location.href = `/${pagination.location}${sorting}/${pagination.skip - pagination.first}/${pagination.first}`}
                                style={pagination.skip > 0 ? {cursor: 'pointer', ...styles.paginationButton} : styles.paginationButton}>
                            {"<"}
                        </button>
                        <button disabled={Math.round(pagination.skip / pagination.first)+1 === Math.ceil(pagination.totalCount / pagination.first)}
                                onClick={() => window.location.href = `/${pagination.location}${sorting}/${pagination.skip + pagination.first}/${pagination.first}`}
                                style={Math.round(pagination.skip / pagination.first)+1 === Math.ceil(pagination.totalCount / pagination.first) ? styles.paginationButton : {cursor:"pointer", ...styles.paginationButton}}>
                            {">"}
                        </button>*/}
                        <select style={{"border":"none","background":"transparent","outline":"0"}} onChange={(e) => {
                            window.location.href = `/${pagination.location}${sorting}/${pagination.first * Number(e.target.value)}/${pagination.first}`
                        }}>
                            {allPages}
                        </select>
                    </div>
                </div>
            }
            <div>
                <DashboardTable header={tableHeader} data={data} headerLink={headerLink}/>
            </div>
            {pagination &&
                <div style={styles.paginationContainer}>
                    <div style={styles.paginationCount}>
                        Page {Math.round(pagination.skip / pagination.first)+1}/{Math.ceil(pagination.totalCount / pagination.first)} (total: {pagination.totalCount})
                    </div>
                    <div style={styles.paginationButtonContainer}>
                        {/*<button disabled={pagination.skip <= 0}
                                onClick={() => window.location.href = `/${pagination.location}${sorting}/${pagination.skip - pagination.first}/${pagination.first}`}
                                style={pagination.skip > 0 ? {cursor: 'pointer', ...styles.paginationButton} : styles.paginationButton}>
                            {"<"}
                        </button>
                        <button disabled={Math.round(pagination.skip / pagination.first)+1 === Math.ceil(pagination.totalCount / pagination.first)}
                                onClick={() => window.location.href = `/${pagination.location}${sorting}/${pagination.skip + pagination.first}/${pagination.first}`}
                                style={Math.round(pagination.skip / pagination.first)+1 === Math.ceil(pagination.totalCount / pagination.first) ? styles.paginationButton : {cursor:"pointer", ...styles.paginationButton}}>
                            {">"}
                        </button>*/}
                        <select style={{"border":"none","background":"transparent","outline":"0"}} onChange={(e) => {
                            window.location.href = `/${pagination.location}${sorting}/${pagination.first * Number(e.target.value)}/${pagination.first}`
                        }}>
                            {allPages}
                        </select>
                    </div>
                </div>
            }
        </div>
    )
}

const styles = {
    paginationContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginLeft: "5vmax",
        marginRight: "5vmax",
        marginTop: "1vmax",
    },
    paginationButtonContainer: {
        display: "flex",
        width: "70px",
        background: "#efefef",
        borderRadius: "5px",
        height: "30px",
        justifyContent: "space-around",
        alignItems: "center",
        boxShadow: "0px 2px 7px rgba(66,66,66,.51)",
        webkitBoxShadow: "0px 2px 7px rgba(66,66,66,.51)",
        mozBoxShadow: "0px 2px 7px rgba(66,66,66,.51)",

    },
    paginationButton: {
        fontWeight: 800,
        color: "#1b1b1b",
        width: "50%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none"
    },
    paginationCount: {
        display: "flex",
        alignItems: "center",
        marginRight: "10px"
    }
};

export default TableContainer;
