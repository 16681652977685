import React from "react";
import "../../styles/Recipe.css"
import photo from "../../img/photo.png"
import ContentEditable from "react-contenteditable";
import {client, PAGE_SIZE} from "../App";
import NEW_RECIPE from "../../graphql/mutations/NewRecipe";
import UPLOAD_FILE from "../../graphql/mutations/UploadFile";
import {PrettyString} from "../Products/ProductCell";
import {Link} from 'react-router-dom';

interface Recipe {
    title: string
    photo: string
    time: number
    number_of_people: number
    author: string
    visible: boolean
    ingredients: Array<string>
    description: string
}

interface Props {
    data: Recipe
}

interface State {
    data: Recipe
    valid: boolean
    img: any
    contentEditable: any
    html: string
    photo: any
}

export class AddRecipeForm extends React.Component<Props, State> {
    constructor(props:Props) {
        super(props);
        let html = "";
        if (props.data.ingredients.length) {
            props.data.ingredients.forEach(e => {
                html = html.concat("<div className='list-item'>", e, "</div>");
            })
        } else
            html = "<div className='list-item'></div>";
        this.state = {
            data: this.props.data,
            valid: false,
            img:null,
            contentEditable: React.createRef(),
            html: html,
            photo: null
        };
        this.getIngredients = this.getIngredients.bind(this);
        this.handleIngChange = this.handleIngChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    getIngredients() {
        let domList = document.createElement("div");
        domList.innerHTML = this.state.html;
        let ingList = [];
        for (let i = 0; i < domList.childElementCount; i++)
                ingList.push((domList.childNodes[i] as any).innerText);
        //console.log(ingList);
        let tmp = this.state.data;
        tmp.ingredients = ingList;
        this.setState({data: tmp});
    }

    validateData = () => {
        let tmp = this.state.data;
        if (tmp.title.length && Number(tmp.number_of_people) && Number(tmp.time) && tmp.author.length && tmp.ingredients.length && tmp.description.length && this.state.photo)
            this.setState({valid: true});
        else
            this.setState({valid: false});
    };

    handleIngChange = (evt: any) => {
        this.setState({html: evt.target.value});
        this.getIngredients();
        this.validateData();
    };

    getBase64(file:Blob) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let tmp = this.state.data;
        let cb = this.setState.bind(this);
        let val = this.validateData;
        reader.onload = function () {
            if (typeof reader.result === "string") {
                tmp.photo = reader.result;
                if (tmp.photo.length)
                    cb({img: tmp.photo});
                else
                    cb({img: photo});
                cb({data: tmp});
                val();
            }
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }

    handleChange(e:any) {
        let tmp = this.state.data;
        e.target.name === "title" && (tmp.title = e.target.value);
        if (e.target.name === "photo" && e.target.files[0]) {
            this.getBase64(e.target.files[0]);
            this.setState({photo: e.target.files[0]});
        }
        e.target.name === "time" && (tmp.time = e.target.value);
        e.target.name === "number_of_people" && (tmp.number_of_people = e.target.value);
        e.target.name === "author" && (tmp.author = e.target.value);
        e.target.name === "onoffswitch" && (tmp.visible = e.target.checked);
        e.target.name === "description" && (tmp.description = e.target.value);
        this.setState({data: tmp});
        this.validateData();
    }

    handleSubmit(e:any) {
        let tmp = this.state.data;
        if (this.state.valid) {
            client.mutate({
                variables: {file: this.state.photo},
                context: {hasUpload: true},
                mutation: UPLOAD_FILE,
                fetchPolicy: 'no-cache'
            }).then(result => {
                //console.log(result);
                client.mutate({mutation: NEW_RECIPE, variables: {
                    title: tmp.title,
                    photo: result.data.uploadSingleFile.id,
                    time: Number(tmp.time),
                    number_of_people: Number(tmp.number_of_people),
                    author: PrettyString(tmp.author.toLowerCase()),
                    visible: tmp.visible,
                    ingredients: tmp.ingredients,
                    description: tmp.description
                }}).then(result => {
                    window.location.reload();
                }).catch(error => {
                    //console.log(error)
                })
            })
        }
    }

    render() {
        let closeStyle = {alignSelf: "flex-end", fontSize: "5vmin", marginTop: "3vmin", marginRight: "3vmin", textDecoration: "none", color: "black"};
        return (
            <div className="nrecipe-container">
                <Link to={"/recettes/0/" + PAGE_SIZE} style={closeStyle}>x</Link>
                <div className="nrecipe-header">
                    <h1>Fiche Recette</h1>
                </div>
                <div className="nrecipe-form-container">
                    <div className="nrecipe-form-header">
                        <div className="nrecipe-form-left">
                            <div className="form-row">
                                <h4 className="input-recipe-label">Nom de la recette:</h4>
                                <input type="text" value={this.state.data.title} name="title" onChange={e => this.handleChange(e)} className="input-recipe input-recipe-header"/>
                            </div>
                            <div className="form-row">
                                <h4 className="input-recipe-label">Temps de préparation:</h4>
                                <input type="number" min={1} value={this.state.data.time} onChange={e => this.handleChange(e)} name="time" className="input-recipe input-recipe-header"/>
                            </div>
                            <div className="form-row">
                                <h4 className="input-recipe-label">Nombre de personnes:</h4>
                                <input type="number" min={1} value={this.state.data.number_of_people} onChange={e => this.handleChange(e)} name="number_of_people" className="input-recipe input-recipe-header"/>
                            </div>
                            <div className="form-row">
                                <h4 className="input-recipe-label">Auteur:</h4>
                                <input type="text" value={this.state.data.author} onChange={e => this.handleChange(e)} name="author" className="input-recipe input-recipe-header"/>
                            </div>
                            <div className="form-row">
                                <h4 className="input-recipe-label">Recette visible sur l'application:</h4>
                                <div className="onoffswitch">
                                    <input checked={this.state.data.visible} onChange={e => this.handleChange(e)} type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" />
                                    <label className="onoffswitch-label" htmlFor="myonoffswitch" />
                                </div>
                            </div>
                            <div className="form-row">
                                <h4 className="input-recipe-label">Télécharger une photo:</h4>
                                    <input id="photo-input" name="photo" onChange={e => this.handleChange(e)} type="file" style={{display:"none"}}/>
                                    <input value={this.state.data.photo} readOnly style={{display:"none"}}/>
                                    <label htmlFor="photo-input">
                                        <img src={photo} alt={"upload"}/>
                                    </label>
                            </div>
                            <div className="form-row" style={this.state.img ? {} : {display:"none"}}>
                                <div className="form-col">
                                    <h4 className="input-recipe-label">Aperçu de la photo:</h4>
                                    <img alt="" className="form-img" src={this.state.img}/>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="nrecipe-form-right">
                            <div>
                                <h4 className="input-recipe-label">Ingrédients nécessaires:</h4>
                                <ContentEditable className="list-container" html={this.state.html} onChange={this.handleIngChange} onKeyUp={this.getIngredients} disabled={false} />
                            </div>
                        </div>
                    </div>
                    <div className="form-description-container">
                        <h1 className="form-description-label">Description de la recette</h1>
                        <textarea value={this.state.data.description} name="description" onChange={this.handleChange} className="input-recipe input-recipe-text" />
                    </div>
                    <button onClick={this.handleSubmit} disabled={!this.state.valid} className={(!this.state.valid) ? "button-recipe dheader-button dheader-button-disabled " : "button-recipe dheader-button"}>Enregistrer la recette</button>
                </div>
            </div>
        )
    }
}

export function AddRecipe() {
    const initial_values:Recipe = {
        title:"",
        photo:"",
        time:1,
        number_of_people:1,
        author:"",
        visible:false,
        ingredients:[""],
        description:"",
    };

    return(
        <AddRecipeForm data={initial_values} />
    )
}

export default AddRecipe;
