import "../../styles/DashboardTable.css"
import React from "react";

export const LoadingCell = (cells :Array<string>) => {
    let shimmers =  cells.map((cellClass:string) => {
        if (!cellClass.length)
            return (<div className="dash-table-empty"><div className={"shimmer"}/></div>);
        return (<div className="dash-table-cell"><div className={"shimmer " + cellClass} /></div>)
    });
    return (
        <div className="dash-table-row">
            {shimmers}
        </div>
    )
};

export default LoadingCell;