import gql from "graphql-tag";

const GET_PRODUCTS = gql`
    query Products($query: String, $sort: ProductOrderByInput, $skip: Int, $first: Int, $type:ProductType, $varietyName: String) {
        products(orderBy: $sort, skip: $skip, first: $first, where: {
            OR:[
                {name_contains: $query}
                {variety: {name_contains: $query}}
                {verbose_type_contains: $query}
            ],AND: [{deleted: false} {type: $type} {variety: {name_contains: $varietyName}}]
        }) {
            type,
            variety {
                name,
                id
            },
            name,
            photo {
                id
            },
            price,
            promotion,
            unit,
            origin,
            stock
            description,
            recipe {
                title,
                id
            },
            visible,
            id
        }
    }
`;

export const GET_STOCK_PRODUCTS = gql`
    query Products($query: String, $sort: ProductOrderByInput, $skip: Int, $first: Int, $type:ProductType, $varietyName: String) {
        products(orderBy: $sort, skip: $skip, first: $first, where: {
            OR:[
                {name_contains: $query}
                {variety: {name_contains: $query}}
                {verbose_type_contains: $query}
            ],AND: [{deleted: false} {type: $type} {variety: {name_contains: $varietyName}, stock_gt: 0}]
        }) {
            type,
            variety {
                name,
                id
            },
            name,
            photo {
                id
            },
            price,
            promotion,
            unit,
            origin,
            stock
            description,
            recipe {
                title,
                id
            },
            visible,
            id
        }
    }
`;

export const GET_STOCK_PRODUCTS_2 = gql`
    query ProductsOrderStock($skip: Int, $first: Int) {
        orders(skip: $skip, first: $first, where: {status: NEED_VALIDATION}) {
            items {
                product {
                    type,
                    variety {
                        name,
                        id
                    },
                    name,
                    photo {
                        id
                    },
                    price,
                    promotion,
                    unit,
                    origin,
                    stock
                    description,
                    recipe {
                        title,
                        id
                    },
                    visible,
                    id,
                    verbose_type
                }
                count
            }
        }
    }
`

export default GET_PRODUCTS;
