import gql from "graphql-tag";
import { client } from "../../components/App";

export const GET_FILE = gql`
    query GetFile($id: ID!) {
        getFile(id: $id) {
            data,
            File {
                filename,
                mimeType
            }
        }
    }
`;

export const getFile = (id:string, cb:(data:string) => any) => {
    client.query({
        query: GET_FILE,
        variables: {id}
    }).then(result => {
        cb(result.data.getFile.data)
    })
};

export default getFile;