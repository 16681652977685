import React, {Component} from 'react';
import ContentEditable from 'react-contenteditable';
import {client} from '../App';
import ADD_DELIVERY_LOCATION from '../../graphql/mutations/NewDeliveryLocation';

interface NewDeliveryLocation {
    locations: Array<string>
    hour_start: string
    hour_end: string
    day: string
}

interface Props {
    data: NewDeliveryLocation
}

interface State {
    data: NewDeliveryLocation
    valid: boolean
    html: string
}

class DeliveryLocationFormDesign extends Component<Props, State> {

    constructor(props:Props) {
        super(props);
        this.state = {
            data: this.props.data,
            valid: false,
            html: "<div className='list-item'></div>"
        };
        this.getCities = this.getCities.bind(this);
        this.handleCitiesChange = this.handleCitiesChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    getCities() {
        let domList = document.createElement("div");
        domList.innerHTML = this.state.html;
        let ingList = [];
        for (let i = 0; i < domList.childElementCount; i++)
                ingList.push((domList.childNodes[i] as any).innerText);
        let tmp = this.state.data;
        tmp.locations = ingList;
        this.setState({data: tmp});
    }

    handleCitiesChange(e:any) {
        this.setState({html: e.target.value});
        this.getCities();
        this.validateData();
    }

    handleChange(e:any) {
        let tmp = this.state.data;
        e.target.name === "day" && (tmp.day = e.target.value);
        e.target.name === "hour_start" && (tmp.hour_start = e.target.value);
        e.target.name === "hour_end" && (tmp.hour_end = e.target.value);
        this.setState({data: tmp});
        this.validateData();
    }

    handleSubmit(e:any) {
        e.preventDefault();
        let newData = this.state.data;
        newData.locations.forEach((loc:string) => {
            client.mutate({
                mutation: ADD_DELIVERY_LOCATION,
                variables: {
                    locations: loc.toLowerCase(),
                    hour_start: newData.hour_start,
                    hour_end: newData.hour_end,
                    day: newData.day.replace("/ /g", "")
                }
            }).then((res) => {
                //console.log(res);
                window.location.reload();
            })
        });
    }

    validateData() {
        let tmp = this.state.data;
        if (tmp.day.length && tmp.hour_end.length && tmp.hour_start && tmp.locations.length)
            this.setState({valid: true});
        else
            this.setState({valid: false});
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                <form className="manage-flex" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Jour de livraison:</h4>
                        <input value={this.state.data.day} name="day" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">À partir de:</h4>
                        <input value={this.state.data.hour_start} name="hour_start" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Jusqu'à:</h4>
                        <input value={this.state.data.hour_end} name="hour_end" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Liste des villes:</h4>
                        <ContentEditable className="list-container mid-large" html={this.state.html} onChange={this.handleCitiesChange} onKeyUp={this.getCities} disabled={false} />
                    </div>
                    <input disabled={!this.state.valid} type="submit" id="addDelivery" style={{display: "none"}} />
                </form>
                <label className={this.state.valid ? "button-recipe dheader-button" : "button-recipe dheader-button dheader-button-disabled "} htmlFor={"addDelivery"}>Ajouter la zone</label>
            </>
        )
    }
}

export function AddDeliveryLocationForm() {
    let initial_values:NewDeliveryLocation = {
        locations: [],
        day: "",
        hour_end: "",
        hour_start: ""
    };

    return (
        <DeliveryLocationFormDesign data={initial_values} />
    )
}

export default AddDeliveryLocationForm;
