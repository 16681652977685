import {DeliveryLocation} from '../../types/DeliveryLocation';
import React, {Component} from 'react';
import {PrettyString} from "../Products/ProductCell";
import {client} from "../App";
import {UPDATE_DELLOC_DISABLED} from "../../graphql/mutations/UpdateDeliveryLocation";

interface deliveryCodeProps {
    delLoc: DeliveryLocation
}

interface deliveryCodeState {
    disabled: boolean
}

class DeliveryCodeListCell extends Component<deliveryCodeProps, deliveryCodeState> {

    constructor(props: deliveryCodeProps) {
        super(props);
        this.state = {
            disabled: this.props.delLoc.disabled
        };
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange() {
        client.mutate({mutation: UPDATE_DELLOC_DISABLED, variables: {
            disabled: !this.state.disabled,
            id: this.props.delLoc.id
        }}).then((res) => {
            this.setState({
                disabled: !this.state.disabled
            })
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let cities;
        if (typeof(this.props.delLoc.locations === "string"))
            cities = <div>{this.props.delLoc.locations}</div>;
        else {
            if (typeof this.props.delLoc.locations !== "string") {
                cities = this.props.delLoc.locations.map((city) =>
                    <div>{PrettyString(city)}</div>
                );
            }
        }
        //console.log(typeof (this.props.delLoc.locations));
        return (
            <div className="dash-table-row">
                <div style={{fontSize: ".7vw", wordBreak: "break-word"}} className="dash-table-cell">{this.props.delLoc.day}</div>
                <div style={{fontSize: ".7vw", wordBreak: "break-word"}} className="dash-table-cell">{this.props.delLoc.hour_start}-{this.props.delLoc.hour_end}</div>
                <div className="dash-table-cell" style={{fontSize: ".7vw", wordBreak: "break-word"}}>{PrettyString(typeof this.props.delLoc.locations === "string" ? this.props.delLoc.locations : "")}</div>
                <div className="dash-table-cell">
                    <div className="onoffswitch">
                        <input onChange={this.handleChange} checked={!this.state.disabled} type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id={"myonoffswitch" + this.props.delLoc.id} />
                        <label className="onoffswitch-label" htmlFor={"myonoffswitch" + this.props.delLoc.id} />
                    </div>
                </div>
            </div>
        )
    }
}

export default DeliveryCodeListCell;
