import React from "react";
import {client} from "../App";
import ADD_COMPANY_CODE from "../../graphql/mutations/NewCompanyCode";
import ALL_DELIVERY_LOCATIONS from "../../graphql/queries/getAllDeliveryLocation";

interface NewCompanyCode {
    code: string,
    address: string,
    postalCode: string,
    city: string
}

interface Props {
    data: NewCompanyCode
}

interface State {
    data: NewCompanyCode,
    valid: boolean
}

export class AddCompanyCodeFormDesign extends React.Component<Props, State> {
    constructor(props:Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.state = {
            data: this.props.data,
            valid: false
        }
    }

    validateData() {
        let data = this.state.data;
        client.query({query: ALL_DELIVERY_LOCATIONS}).then((res) => {
            if (res.data.deliveryLocations.find((loc: any) => {
                return (loc.locations === data.city.toLowerCase())
            })) {
                if (data.code.length && data.city.length && data.postalCode.length && data.address.length)
                    this.setState({valid: true});
                else
                    this.setState({valid: false});
            } else
                this.setState({valid: false});
        });
    }

    handleChange(e:any) {
        let tmp = this.state.data;
        e.target.name === "code" && (tmp.code = e.target.value);
        e.target.name === "address" && (tmp.address = e.target.value);
        e.target.name === "city" && (tmp.city = e.target.value);
        e.target.name === "postalCode" && (tmp.postalCode = e.target.value);
        this.setState({data: tmp});
        this.validateData();
    }

    handleSubmit(e:any) {
        e.preventDefault();
        let tmp = this.state.data;
        client.mutate({
            mutation: ADD_COMPANY_CODE,
            variables: {
                code: tmp.code,
                city: tmp.city.toLowerCase(),
                postalCode: tmp.postalCode,
                address: tmp.address
            }
        }).then((res) => {
            //console.log(res);
            window.location.reload();
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                <form className="manage-flex" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Code:</h4>
                        <input value={this.state.data.code} name="code" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Adresse:</h4>
                        <input value={this.state.data.address} name="address" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Code postal:</h4>
                        <input value={this.state.data.postalCode} name="postalCode" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <div className="form-row">
                        <h4 className="input-recipe-label">Ville:</h4>
                        <input value={this.state.data.city} name="city" onChange={e => this.handleChange(e)} className="input-recipe"/>
                    </div>
                    <input disabled={!this.state.valid} type="submit" id="addCompanyCode" style={{display: "none"}} />
                </form>
                <label className={this.state.valid ? "button-recipe dheader-button" : "button-recipe dheader-button dheader-button-disabled "} htmlFor={"addCompanyCode"}>Ajouter le code entreprise</label>
            </>
        )
    }
}

export function AddCompanyCodeForm() {
    let initial_values:NewCompanyCode = {
        code: "",
        address: "",
        postalCode: "",
        city: ""
    };
    return(<AddCompanyCodeFormDesign data={initial_values}/>)
}
