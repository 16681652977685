import gql from "graphql-tag";

const REM_USER = gql`
    mutation RemoveUser($id : ID!) {
        deleteUser(where: {id: $id}) {
            id
        }
    }
`;

export default REM_USER;