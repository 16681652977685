import gql from 'graphql-tag';

const REM_VARIETY = gql`
    mutation RemoveVariety($id: ID!) {
        deleteVariety(where: {id: $id}) {
            id
        }
    }
`;

export default REM_VARIETY;
