import gql from 'graphql-tag';

const ALL_DELIVERY_LOCATIONS = gql`
    query {
        deliveryLocations {
            id,
            locations,
            hour_start,
            hour_end,
            day,
            disabled
        }
    }
`;

export default ALL_DELIVERY_LOCATIONS;
