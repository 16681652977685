import React, {useState} from "react";
import { User } from "../../types/Users";
import "../../styles/DashboardTable.css"
import {client} from "../App";
import REM_USER from "../../graphql/mutations/RemoveUser";
import GET_USER from "../../graphql/queries/getSingleUser";

interface userProps {
    user: User
}

const ConfirmPopup = (setPopup:any, popup:boolean, handleRemove: (userId: string) => void, userId: string) : React.ReactElement => {
    return(
        <div className="confirm-container">
            <div className="confirm-content">
                <div className="confirm-box">
                    <div onClick={() => setPopup(!popup)} className="confirm-close">✕</div>
                    <div style={{margin: "30px"}}>Confirmez la suppression du compte ?</div>
                    <div className="confirm-button-container">
                        <div onClick={() => handleRemove(userId)} className="confirm-button-yes">Oui</div>
                        <div onClick={() => setPopup(!popup)} className="confirm-button-no">Non</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const handleRemove = (userId: string) => {
    client.mutate({
        mutation: REM_USER,
        variables: {id: userId},
        refetchQueries: [{query: GET_USER, variables: {id: userId}}]
    }).then(res => {
        window.location.reload();
    })
};

export function UsersListCell(props: userProps) {

    let [popup, setPopup] = useState(false);
    let popupComponent = ConfirmPopup(setPopup, popup, handleRemove, props.user.id);

    return (
        <>
        {popup && popupComponent}
        <div className="dash-table-row">
            <div className="dash-table-empty"><button onClick={() => setPopup(!popup)} className="dash-table-button">X</button></div>
            <div className="dash-table-cell">{props.user.name}</div>
            <div className="dash-table-cell">{props.user.surname}</div>
            <div className="dash-table-cell">
                <div className="dash-table-cell-content">{props.user.email}</div>
            </div>
            <div className="dash-table-cell dash-table-cell-reduce" style={{fontSize: ".7vw"}}>{props.user.phone}</div>
            <div className="dash-table-cell">
                <div className="dash-table-cell-content">
                {props.user.company_code ?
                    props.user.company_code.address + " " + props.user.company_code.city + " " + props.user.company_code.postalCode :
                    props.user.address + " " + props.user.city + " " + props.user.postalCode
                }
                </div>
            </div>
            <div className="dash-table-cell" style={{fontSize: ".7vw"}}>
                {props.user.company_code ?
                    props.user.company_code.code :
                    "Pas de code entreprise"
                }
            </div>
            <div className="dash-table-cell dash-table-cell-reduce">{props.user.orders.length}</div>
        </div>
        </>
    )
}

export default UsersListCell;
