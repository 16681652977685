import React from "react";
import {GET_VARIETIES_FORM} from "../../graphql/queries/getAllVariety";
import {client} from "../App";
import {PrettyString} from "../Products/ProductCell";

export interface ProductSelectProps {
    getVarieties: boolean
    setter: (val: string) => void
    type: string
}


interface ProductSelectState {
    options:Array<Array<string>>
    loading:boolean
}

class ProductSelect extends React.Component<ProductSelectProps,ProductSelectState> {
    constructor(props:ProductSelectProps) {
        super(props);
        this.state = {options: [["Chargement...", ""]], loading: false};
    }

    componentDidMount(): void {
        if (this.props.getVarieties) {
            //console.log(this.props.type);
            if (this.props.type.length) {
                client.query({query: GET_VARIETIES_FORM, variables:{type: this.props.type}}).then((result:any) => {
                    let options = [];
                    options.push(["Tout afficher", ""]);
                    for (let i = 0; i < result.data.varieties.length; i++) {
                        options.push([PrettyString(result.data.varieties[i].name), result.data.varieties[i].name])
                    }
                    this.setState({options: options});
                })
            } else {
                client.query({query: GET_VARIETIES_FORM}).then((result:any) => {
                    let options = [];
                    options.push(["Tout afficher", ""]);
                    for (let i = 0; i < result.data.varieties.length; i++) {
                        options.push([PrettyString(result.data.varieties[i].name), result.data.varieties[i].name])
                    }
                    this.setState({options: options});
                })
            }
        } else {
            if (this.props.type === "FRUIT")
                this.setState({options: [["Fruits & Légumes", ""], ["Fruit", "FRUIT"]]});
            else if (this.props.type === "VEGETABLE")
                this.setState({options: [["Fruits & Légumes", ""], ["Légumes", "VEGETABLE"]]});
            else
                this.setState({options: [["Fruits & Légumes", ""], ["Fruit", "FRUIT"], ["Légumes", "VEGETABLE"]]});
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let options = this.state.options.map((opt:Array<string>) => <option value={opt[1]}>{opt[0]}</option>);
        return (
            <select style={{fontFamily: "'Nunito', serif", overflow: "hidden", whiteSpace: "pre", textOverflow: "ellipsis", width: "100%", backgroundColor: "#28AF63", color: "#F7F7F7"}} onChange={(e:any) => {this.props.setter(e.target.value)}}>
                {options}
            </select>
        )
    }
}

export default ProductSelect;
