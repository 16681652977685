import React from "react";
import {client} from "../App";
import GET_USER_LIST from "../../graphql/queries/getUserList";
import GET_ORDER_DETAILS from "../../graphql/queries/getOrderDetails";
import GET_SALES from "../../graphql/queries/getSales";

interface userResponse {
    getUserList: GetUserList;
}

interface GetUserList {
    data: string;
    File: File;
    __typename: string;
}

interface orderResponse {
    getOrderDetails: GetOrderDetails;
}

interface salesResponse {
    getSalesList: GetOrderDetails;
}

interface GetOrderDetails {
    data: string;
    File: File;
    __typename: string;
}

interface File {
    filename: string;
    mimeType: string;
    encoding: string;
    __typename: string;
}

interface ExportButtonProps {
    type: "user" | "order"|"sales"
    orderId?: string
    orderNb?: string
    orderIds?: Array<string>
}

function _base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const ExportButton = (props: ExportButtonProps) => {

    function saveFile(name: string, type: string, data: string) {

        if (props.type === "order") {
            const pdfBlob = new Blob([_base64ToArrayBuffer(data)], { type: "application/pdf" });
            const url = URL.createObjectURL(pdfBlob);
            window.open(url, "_blank")
            console.log("toto")
            /*const winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
                'resizable,screenX=50,screenY=50,width=850,height=1050';
            const printWindow = window.open("", "PDF", winparams);
            if (printWindow) {
                printWindow.document.write(`<iframe style="border: none" width='100%' height='100%' src="data:${type};base64,${data}"></iframe>`);
                printWindow.print();
            }*/
        } else {
            const a = document.createElement("a");
            a.href = `data:${type};base64,${data}`;
            a.download = name;
            a.click();
            a.remove()
        }
    }

    const getUsers = () => {
        if (props.type === "user") {
            client.query<userResponse>({query: GET_USER_LIST}).then((res) => {
                saveFile(res.data.getUserList.File.filename, res.data.getUserList.File.mimeType, res.data.getUserList.data)
            })
        } else if (props.type === "order")
            client.query<orderResponse>({query: GET_ORDER_DETAILS, variables: {orderId: props.orderId}}).then((res) => {
                saveFile(res.data.getOrderDetails.File.filename, res.data.getOrderDetails.File.mimeType, res.data.getOrderDetails.data)
            })
        else if (props.type === "sales") {
            client.query<salesResponse>({query: GET_SALES, variables: {orderId: props.orderIds}}).then((res) => {
                saveFile(res.data.getSalesList.File.filename, res.data.getSalesList.File.mimeType, res.data.getSalesList.data)
            })
        }
    }

    if (props.type === "order") {
        return (
            <div style={{cursor: "pointer"}} onClick={getUsers}>{props.orderNb}</div>
        )
    } else if (props.type === "user" || props.type === "sales") {
        return (
            <button className="right dheader-button" onClick={getUsers}>
                {props.type === "sales" ? "Exporter ma selection sur Excel" :"Exporter"}
            </button>
        )
    }
    return null;
}

export default ExportButton;
