import gql from "graphql-tag";

export const GET_ORDER = gql`
    query order($id: ID!, $skip: Int!, $first: Int!) {
        order(where: {id: $id}) {
            id,
            nb,
            items {
                product {
                    id,
                    name,
                    unit
                },
                count,
                price
            },
            shipping_price,
            price,
            status,
            created_at
        }
    }
`;

export const GET_SALES_CODES = gql`
    query orders($startDate: DateTime, $stopDate: DateTime, $cities: [ID!], $codes: [ID!]) {
        orders(where: {
            created_at_gte: $startDate, created_at_lte: $stopDate, delivery_location: {id_in: $cities}, companyCode: {id_in: $codes}
        }) {
            id,
            nb,
            items {
                product {
                    id,
                    name,
                    unit
                    stock
                },
                count,
                price
            },
            shipping_price,
            price,
            address,
            postalCode,
            city,
            status,
            created_at,
            chargeID,
            delivery_location {
                locations,
                hour_end,
                hour_start,
                day
                id
            }
            companyCode {
                id,
                code,
                postalCode,
                city,
                address
            }
        }
    }
`

export const GET_SALES_NO_CODES = gql`
    query orders($startDate: DateTime, $stopDate: DateTime, $cities: [ID!], $codes: [ID!]) {
        orders(where: {
            created_at_gte: $startDate, created_at_lte: $stopDate, delivery_location: {id_in: $cities}, companyCode: {id_not_in: $codes}
        }) {
            id,
            nb,
            items {
                product {
                    id,
                    name,
                    unit
                    stock
                },
                count,
                price
            },
            shipping_price,
            price,
            address,
            postalCode,
            city,
            status,
            created_at,
            chargeID,
            delivery_location {
                locations,
                hour_end,
                hour_start,
                day
                id
            }
            companyCode {
                id,
                code,
                postalCode,
                city,
                address
            }
        }
    }
`

export const GET_SALES = gql`
    query orders($startDate: DateTime, $stopDate: DateTime, $cities: [ID!]) {
        orders(orderBy: created_at_DESC, where: {
            created_at_gte: $startDate, created_at_lte: $stopDate, delivery_location: {id_in: $cities}
        }) {
            id,
            nb,
            items {
                product {
                    id,
                    name,
                    unit
                    stock
                },
                count,
                price
            },
            shipping_price,
            price,
            address,
            postalCode,
            city,
            status,
            created_at,
            chargeID,
            delivery_location {
                locations,
                hour_end,
                hour_start,
                day
                id
            }
            companyCode {
                id,
                code,
                postalCode,
                city,
                address
            }
        }
    }
`

const GET_ORDERS = gql`
    query orders($query: String, $skip: Int, $first: Int) {
        orders(orderBy: created_at_DESC, skip: $skip, first: $first, where: {
            archived: false,
            OR: [
                {city_contains: $query}, {nb_contains: $query}, {postalCode_contains: $query}, {address_contains: $query},
                {companyCode: {code_contains: $query}}, {companyCode: {address_contains: $query}}, {companyCode: {postalCode_contains: $query}},
                {companyCode: {city_contains: $query}}
            ]
        }) {
            id,
            nb,
            items {
                product {
                    id,
                    name,
                    unit
                    stock
                },
                count,
                price
            },
            shipping_price,
            price,
            address,
            postalCode,
            city,
            status,
            created_at,
            chargeID,
            delivery_location {
                locations,
                hour_end,
                hour_start,
                day
                id
            }
            companyCode {
                id,
                code,
                postalCode,
                city,
                address
            }
        }
    }
`;

export const GET_ARCHIVED_ORDERS = gql`
    query orders($query: String, $skip: Int, $first: Int) {
        orders(orderBy: created_at_DESC, skip: $skip, first: $first, where: {
            archived: true,
            OR: [
                {city_contains: $query}, {nb_contains: $query}, {postalCode_contains: $query}, {address_contains: $query},
                {companyCode: {code_contains: $query}}, {companyCode: {address_contains: $query}}, {companyCode: {postalCode_contains: $query}},
                {companyCode: {city_contains: $query}}
            ]
        }) {
            id,
            nb,
            items {
                product {
                    id,
                    name,
                    unit
                },
                count,
                price
            },
            shipping_price,
            price,
            address,
            postalCode,
            city,
            status,
            created_at,
            chargeID,
            delivery_location {
                locations,
                hour_end,
                hour_start,
                day
                id
            }
            companyCode {
                id,
                code,
                postalCode,
                city,
                address
            }
        }
    }
`;

export default GET_ORDERS;
