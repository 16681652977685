import gql from "graphql-tag";

const ARCHIVE_ORDER = gql`
    mutation ArchiveOrder($id: ID!, $archived: Boolean!) {
        updateOrder(where: {id: $id}, data: {archived: $archived}) {
            archived
        }
    }
`;

export const UNARCHIVE_ORDER = gql`
    mutation ArchiveOrder($id: ID!) {
        updateOrder(where: {id: $id}, data: {archived: false}) {
            id
        }
    }
`;

export default ARCHIVE_ORDER;
