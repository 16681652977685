import React from "react";
import "../../styles/Forms.css"
import photo from "../../img/photo.png"
import {client, PAGE_SIZE} from "../App";
import NEW_PRODUCT from "../../graphql/mutations/NewProduct";
import UPLOAD_FILE from "../../graphql/mutations/UploadFile";
import {GET_RECIPES_FORM} from "../../graphql/queries/getAllRecipes";
import {PrettyString} from "./ProductCell";
import LoadingCell from "../Misc/LoadingCell";
import {GET_FILE} from "../../graphql/queries/getFile";
import UPDATE_PRODUCT, {UPDATE_PRODUCT_NOFILE} from "../../graphql/mutations/UpdateProduct";
import {GET_VARIETIES_FORM} from "../../graphql/queries/getAllVariety";

interface Product {
    type: string
    variety: string
    name: string
    photo: string
    price: number
    promotion?: number
    unit: string
    origin: string
    description: string
    recipe?: Array<any>
    visible: boolean
}

interface Props {
    data: Product
    edit: boolean
    id?: string
    recipes?: Array<string>
}

interface State {
    data: Product
    valid: boolean
    upBG: any
    file: any
    recipes: any
    varieties: any
    loading: boolean
    fileChanged: boolean
}

export class AddProductFormDesign extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.state = {
            data: this.props.data,
            valid: false,
            upBG: photo,
            file: null,
            recipes: null,
            varieties: null,
            loading: true,
            fileChanged: false
        }
    }

    handleTypeChange(): void {
        client.query({query: GET_VARIETIES_FORM, variables:{type: this.state.data.type}}).then((result) => {
            let tmp2 = [];
            tmp2.push(<option key="0" disabled value="" style={{display: "none"}} />);
            for (let i = 0; i < result.data.varieties.length; i++) {
                tmp2.push(<option key={result.data.varieties[i].id} value={result.data.varieties[i].id}>{PrettyString(result.data.varieties[i].name)}</option>)
            }
            this.setState({varieties: tmp2});
        })
    }

    componentDidMount(): void {
        if (this.props.edit)
            client.query({query: GET_FILE, variables: {id: this.props.data.photo}}).then(result => {
                this.setState({upBG: "data:" + result.data.getFile.File.mimeType + ";base64," + result.data.getFile.data, valid: true})
            });
        client.query({query: GET_RECIPES_FORM}).then(result => {
            let tmp = [];
            for (let i = 0; i < result.data.recipes.length; i++) {
                tmp.push(<option key={result.data.recipes[i].id} value={result.data.recipes[i].id}>{PrettyString(result.data.recipes[i].title)}</option>)
            }
            this.setState({recipes: tmp, loading: false});
            this.handleTypeChange();
        });
    }

    getBase64(file:Blob) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let tmp = this.state.data;
        let cb = this.setState.bind(this);
        let val = this.validateData.bind(this);
        reader.onload = function () {
            if (typeof reader.result === "string") {
                tmp.photo = reader.result;
                if (tmp.photo.length)
                    cb({upBG: tmp.photo, fileChanged: true});
                else
                    cb({upBG: photo});
                cb({data: tmp});
                val();
            }
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }

    validateData() {
        let data = this.state.data;
        if (data.type.length && data.name.length && data.variety.length && data.photo.length && data.price !== 0 && data.unit.length && data.origin.length && data.description.length)
            {this.setState({valid: true});}
        else
            this.setState({valid: false});
    }

    handleChange(e:any) {
        let tmp = this.state.data;
        if (e.target.name === "type") {
            tmp.type = e.target.value;
        }
        e.target.name === "photo" && e.target.files[0] && this.getBase64(e.target.files[0]);
        e.target.name === "photo" && e.target.files[0] && this.setState({file: e.target.files[0]});
        e.target.name === "variety" && (tmp.variety = e.target.value);
        e.target.name === "name" && (tmp.name = e.target.value);
        e.target.name === "price" && (tmp.price = parseFloat(e.target.value));
        e.target.name === "unit" && (tmp.unit = e.target.value);
        e.target.name === "origin" && (tmp.origin = e.target.value);
        e.target.name === "description" && (tmp.description = e.target.value);
        e.target.name === "onoffswitch" && (tmp.visible = e.target.checked);
        if (e.target.name === "recipes") {
            let recipes = [];
            for (let i = 0; i < e.target.options.length; i++) {
                if (e.target.options[i].selected)
                    recipes.push({id: e.target.options[i].value})
            }
            tmp.recipe = recipes;
        }
        this.setState({data: tmp});
        this.handleTypeChange();
        this.validateData();
    }

    handleSubmit(e:any) {
        e.preventDefault();
        let tmp = this.state.data;
        if (this.props.edit) {
            if (this.state.fileChanged) {
                client.mutate({
                    variables: {file: this.state.file},
                    context: {hasUpload: true},
                    mutation: UPLOAD_FILE,
                    fetchPolicy: 'no-cache'
                }).then(result => {
                    client.mutate({
                        variables: {
                            type: tmp.type,
                            variety: tmp.variety,
                            name: tmp.name.toLowerCase(),
                            photo: result.data.uploadSingleFile.id,
                            price: tmp.price,
                            promotion: tmp.promotion,
                            unit: tmp.unit,
                            origin: tmp.origin,
                            description: tmp.description,
                            visible: tmp.visible,
                            recipe: tmp.recipe,
                            where: this.props.id
                        },mutation: UPDATE_PRODUCT,
                    }).then(() => {
                        window.location.reload();
                    })
                })
            } else {
                client.mutate({
                    variables: {
                        type: tmp.type,
                        variety: tmp.variety,
                        name: tmp.name.toLowerCase(),
                        price: tmp.price,
                        promotion: tmp.promotion,
                        unit: tmp.unit,
                        origin: tmp.origin,
                        description: tmp.description,
                        visible: tmp.visible,
                        recipe: tmp.recipe,
                        where: this.props.id
                    },mutation: UPDATE_PRODUCT_NOFILE,
                }).then((() => {
                    window.location.reload();
                }))
            }
        } else {
            let file = new FormData();
            file.append(this.state.file.name, URL.createObjectURL(this.state.file));
            client.mutate({
                variables: {file: this.state.file},
                context: {hasUpload: true},
                mutation: UPLOAD_FILE,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                client.mutate({
                    variables: {type: tmp.type,
                        variety: tmp.variety,
                        name: tmp.name.toLowerCase(),
                        photo: result.data.uploadSingleFile.id,
                        price: tmp.price,
                        promotion: tmp.promotion,
                        unit: tmp.unit,
                        origin: tmp.origin,
                        description: tmp.description,
                        visible: tmp.visible,
                        recipe: tmp.recipe
                    },
                    mutation: NEW_PRODUCT,
                }).then(() => { window.location.href = "/produits/type_ASC/0/" + PAGE_SIZE })
                .catch(error => { //console.log(error)
                    });
            }).catch(error => {//console.log(error)
                });
        }
    }

    render() {
        //console.log(this.props.data.type);
        if (this.state.loading)
            return (LoadingCell(["shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-text", "shimmer-switch"]));
        return (
            <>
                <form className="dash-table-row" onSubmit={this.handleSubmit}>
                    <div className="dash-table-cell" style={{padding: 0}}>
                        <select id="type" defaultValue={this.props.data.type} name="type" onChange={e => this.handleChange(e)} className="input-style" style={{resize: "none"}}>
                            <option disabled value="" style={{display: "none"}}/>
                            <option value="FRUIT">Fruit</option>
                            <option value="VEGETABLE">Légume</option>
                        </select>
                    </div>
                    <div className="dash-table-cell" style={{padding: 0}}>
                        <select defaultValue={this.props.data.variety} name="variety" onChange={e => this.handleChange(e)} className="input-style" style={{resize: "none"}}>
                            {this.state.varieties}
                        </select>
                    </div>
                    <input value={this.state.data.name} name="name" onChange={e => this.handleChange(e)} className="input-style dash-table-cell"/>
                    <div className="dash-table-cell" style={{overflow: "hidden", paddingTop: 0, paddingBottom: 0}}>
                        <input name="photo" onChange={e => this.handleChange(e)} type="file" className="input-style input-file"/>
                        <input value={this.state.data.photo} readOnly style={{display:"none"}}/>
                        <img className="input-file-img" src={this.state.upBG} alt={"upload"}/>
                    </div>
                    <input value={this.state.data.price} name="price" onChange={e => this.handleChange(e)} type="number" className="input-style dash-table-cell"/>
                    <input value={this.state.data.unit} name="unit" onChange={e => this.handleChange(e)} className="input-style dash-table-cell"/>
                    <input value={this.state.data.origin} name="origin" onChange={e => this.handleChange(e)} className="input-style dash-table-cell"/>
                    <textarea value={this.state.data.description} name="description" onChange={e => this.handleChange(e)} className="input-style dash-table-cell"/>
                    <div className="dash-table-cell" style={{padding: 0}}>
                        <select defaultValue={this.props.recipes} name="recipes" onChange={e => this.handleChange(e)} multiple className="input-style" style={{resize: "none"}}>
                            {this.state.recipes}
                        </select>
                    </div>
                    <div className="dash-table-cell">
                        <div className="onoffswitch">
                            <input checked={this.state.data.visible} onChange={e => this.handleChange(e)} type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" />
                            <label className="onoffswitch-label" htmlFor="myonoffswitch" />
                        </div>
                    </div>
                    <input disabled={!this.state.valid} type="submit" id="popupForm" style={{display: "none"}} />
                </form>
                <label className={this.state.valid ? "dheader-button PopupButton" : "dheader-button dheader-button-disabled PopupButton"} htmlFor={"popupForm"}>{this.props.edit ? "Enregistrer les modifications" : "Ajouter le produit à la liste"}</label>
            </>
        )
    }
}

export function AddProductForm(product?: any) {
    let initial_values:Product;
    if (product) {
        let tmp = [];
        let recipes = [];
        for (let i = 0; i < product.recipe.length; i++) {tmp.push({id: product.recipe[i].id});recipes.push(product.recipe[i].id);}
        initial_values = {
            type: product.type,
            variety: product.variety.id,
            name: product.name,
            photo: product.photo.id,
            price: product.price,
            promotion: product.promotion,
            unit: product.unit,
            origin: product.origin,
            description: product.description,
            recipe: tmp,
            visible: product.visible
        };
        return(<AddProductFormDesign data={initial_values} edit={!!product} id={product.id} recipes={recipes} />)
    } else {
         initial_values = {
             type: "",
             variety: "",
             name: "",
             photo: "",
             price: 0,
             promotion: 0,
             unit: "",
             origin: "",
             description: "",
             visible: true
        };
        return(<AddProductFormDesign data={initial_values} edit={!!product} />)
    }
}

export default AddProductForm;
